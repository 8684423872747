import { createSlice } from '@reduxjs/toolkit';
import { PartnerEntity, SliceStatus } from 'type-declarations';
import { fetchPartners } from './thunks';
export interface PartnerState {
	status: SliceStatus;
	partners: PartnerEntity[];
}

export const initialPartnerState: PartnerState = {
	status: SliceStatus.IDLE,
	partners: [],
};

export const partnerSlice = createSlice({
	name: 'partner',
	initialState: initialPartnerState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPartners.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		});
		builder.addCase(fetchPartners.fulfilled, (state, action) => {
			state.status = SliceStatus.SUCCEEDED;
			state.partners = action.payload;
		});
		builder.addCase(fetchPartners.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
			state.partners = [];
		});
	},
});

export const partnerReducer = partnerSlice.reducer;
export const partnerActions = partnerSlice.actions;
