import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, post } from 'aws-amplify/api';
import { RHYME_API_SERVICES, UM_INTEGRATIONS } from 'type-declarations';

const validResponseCodes = [200];

export const withDrawSubmission = createAsyncThunk(
	'benefitManager-thunk/withdrawSubmission',
	async ({
		benefitManagerId,
		orgId,
		submissionId,
	}: {
		benefitManagerId: UM_INTEGRATIONS;
		orgId: string;
		submissionId: string;
	}) => {
		const baseUrl = RHYME_API_SERVICES.RhymeApi;
		const umIntegration = UM_INTEGRATIONS[benefitManagerId].toLowerCase();
		const paramaters = `organization/${orgId}/case/{caseId}/submission/${submissionId}/payer-integration/${umIntegration}/withdraw`;

		try {
			await post({
				apiName: baseUrl,
				path: paramaters,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				{
					throw new Error(`Response status of ${statusCode},
        umintegration service expects ${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}
      `);
				}
			}
			throw new Error(`Withdraw submission failed with error: ${error}`);
		}
	}
);
