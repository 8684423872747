import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './services';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	//if ever dealing with sensitive information in state, we must add a rule here
	//to exclude those Redux actions from being included in Sentry.io data.
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			// Check for non-serializable objects stored in state.
			// TODO: Can services be updated to avoid breaking this rule?
			serializableCheck: false,
			// Check for state mutations outside dispatches. RTK defaults to enabled
			// Disable for debugging purposes only!
			immutableCheck: true,
		}),
	enhancers: (getDefaultEnhancers) => {return getDefaultEnhancers().concat(sentryReduxEnhancer)},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
