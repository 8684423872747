import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { validateAndSetDefaults, validatePreCheck } from './thunks';
import { PreCheckValidationResponse, SliceStatus } from 'type-declarations';

export interface PrecheckState {
	preCheckResponse: PreCheckValidationResponse;
	isCheckingValidity: boolean;
	isUpdatingReferral: boolean;
	status: SliceStatus;
}

const initialState: PrecheckState = {
	preCheckResponse: {} as PreCheckValidationResponse,
	isCheckingValidity: false,
	isUpdatingReferral: false,
	status: SliceStatus.IDLE,
};

export const precheckSlice = createSlice({
	name: 'preCheckThunk',
	initialState,
	reducers: {
		resetPreCheckSlice: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(validatePreCheck.pending, (state) => {
			state.isCheckingValidity = true;
			state.preCheckResponse = {} as PreCheckValidationResponse;
		}),
			builder.addCase(validatePreCheck.fulfilled, (state, action: PayloadAction<any>) => {
				state.isCheckingValidity = false;
				state.preCheckResponse = action.payload as PreCheckValidationResponse;
			}),
			builder.addCase(validatePreCheck.rejected, (state) => {
				state.isCheckingValidity = false;
				state.preCheckResponse = {} as PreCheckValidationResponse;
			}),
			builder.addCase(validateAndSetDefaults.pending, (state) => {
				if (state.status === SliceStatus.IDLE) {
					state.status = SliceStatus.PENDING;
				} else state.status = SliceStatus.REFRESHING;
				state.isUpdatingReferral = true;
			}),
			builder.addCase(validateAndSetDefaults.fulfilled, (state) => {
				state.isUpdatingReferral = false;
				state.status = SliceStatus.SUCCEEDED;
			}),
			builder.addCase(validateAndSetDefaults.rejected, (state) => {
				state.isUpdatingReferral = false;
				state.status = SliceStatus.REJECTED;
			});
	},
});

export const precheckReducer = precheckSlice.reducer;
export const precheckActions = precheckSlice.actions;
export const { resetPreCheckSlice } = precheckActions;
