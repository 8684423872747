export interface Survey {
	integrationName: string;
	umIntegrationUrl: string;
	response: MockSurveyResponse;
}

export interface MockSurveyResponse extends Partial<SurveyInfoResponse> {
	id: string;
	actionEndpoint: string;
	transportFormat: string;
}

export interface SurveyInfoResponse {
	payload: string;
	callbackUrl: string;
	requireSubmitConfirmation: boolean;
	closeOnCallback: boolean;
	surveyType: string;
}

export interface SurveySubmitContent {
	request: SurveyInfoRequest;
	url: string;
	umIntegrationUrl: string;
	integrationName: string;
}

export interface SurveyInfoRequest {
	id: string; //submissionId
	userFirstName: string;
	userLastName: string;
	userId: string;
	userEmail: string;
}

export enum TransportFormat {
	FormPost = 'FORMPOST',
	None = 'NONE',
	Get = 'GET',
	Post = 'POST',
	JwtToken = 'JwtToken',
	Html = 'HTML',
	OliveMFE = 'OliveMFE',
}

export enum SurveyType {
	FHIRQuestionnaire = 'FHIRQuestionnaire',
	OliveMFE = 'OliveMFE',
}

export interface TransportKey {
	key: string;
	value: string;
}

export interface FHIRQuestionnairePayload {
	id: string;
	valid: boolean;
	title: string;
	item: FHIRQuestionPayload[];
}

export interface FHIRQuestionPayload {
	linkId: string;
	text: string;
	type: string;
	interface: string;
	required: boolean;
	answerOption: any[];
	answer: string[];
	item: FHIRQuestionPayload[];
	extension: FHIRExtension[];
	indentationLevel: number;
}

export interface FHIRExtension {
	url: string;
	valueCodeableConcept?: FHIRCodeableConcept;
	valueBoolean?: boolean;
}

export interface FHIRCodeableConcept {
	text: string;
	coding: FHIRCoding[];
}

export interface FHIRAnswerOption {
	valueCoding: FHIRCoding;
}

export interface FHIRCoding {
	code: string;
	display: string;
	system: string;
}

export enum IntegrationType {
	AIM = 'AIM',
	AimRpa = 'AimRpa',
	AIMSmr = 'AIMSmr',
	AvailityAetna = 'AvailityAetna',
	AvailityAnthem = 'AvailityAnthem',
	AvailityBcbsMnPsa = 'AvailityBcbsMnPsa',
	AvailityCambia = 'AvailityCambia',
	AvailityFloridaBlue = 'AvailityFloridaBlue',
	AvailityHcsc = 'AvailityHcsc',
	AvailityHumana = 'AvailityHumana',
	Evicore = 'eviCore',
	EvicoreCareCorePsa = 'EvicoreCareCorePsa',
	EvicoreIntellipath = 'eviCoreIntellipath',
	EvicoreMedSolutionsPsa = 'EvicoreMedSolutionsPsa',
	EvicoreUhcPsa = 'EvicoreUhcPsa',
	MCG = 'MCG',
	MOCK = 'MOCK',
	Navinet = 'Navinet',
	NIA = 'NIA',
	NotSupported = 'NotSupported',
	UHC = 'UHC',
	UhcSurgicalPsa = 'UhcSurgicalPsa',
	Undetermined = 'Undetermined',
	OliveFloridaBlue = 'OliveFloridaBlue',
	AuthAIFloridaBlue = 'AuthAIFloridaBlue',
}

export enum IntegrationDisplayNames {
	AIM = 'AIM Specialty Health',
	AIMRPA = 'AIM Specialty Health',
	AIMSMR = 'AIM Specialty Health',
	AVAILITYAETNA = 'Aetna',
	AVAILITYANTHEM = 'Anthem',
	AVAILITYBCBSMNPSA = 'BCBS MN',
	AVAILITYCAMBIA = 'Cambia',
	AVAILITYFLORIDABLUE = 'Florida Blue',
	OLIVEFLORIDABLUE = 'Florida Blue',
	AVAILITYHCSC = 'HCSC',
	AVAILITYHUMANA = 'Humana',
	EVICORE = 'eviCore',
	EVICORECARECOREPSA = 'eviCore',
	EVICOREINTELLIPATH = 'eviCore',
	EVICOREMEDSOLUTIONSPSA = 'eviCore',
	EVICOREUHCPSA = 'UHC',
	NAVINET = 'MMO',
	NIA = 'NIA',
	MOCK = 'Rhyme Mock',
	NOTSUPPORTED = 'Not Supported',
	UNDETERMINED = 'Undetermined',
	UHC = 'UHC',
}
