import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { FC } from 'react';
import { userTableColumns } from './constants';
import Users from './components/Users';
import { isStatusPendingOrRefreshing } from 'slice-utilities';

const AdminUsers: FC = () => {
	const { users, status } = useAppSelector((state) => state.admin.partner.users);

	return (
		<Users
			users={users}
			loading={isStatusPendingOrRefreshing([status])}
			columns={userTableColumns}
		/>
	);
};

export default AdminUsers;
