import { Button, Grid2 as Grid, TextField } from '@mui/material';
import { FC } from 'react';
import { Contact, DefaultContact as DefaultContactModel } from 'type-declarations';
import { useAppDispatch, useAppSelector } from '@hooks/useReduxToolkit';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultContactFormSchema } from './validation';
import { useForm } from 'react-hook-form';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { createProviderDefaultContact } from 'state-services';

interface DefaultContactProperty {
	fieldKey: 'name' | 'phone' | 'email' | 'fax';
	label: string;
}

const DefaultContact: FC = () => {
	const dispatch = useAppDispatch();
	const { defaultContact } = useAppSelector((state) => state.admin.provider.defaultContact);
	const { activeEntity } = useAppSelector((state) => state.user);

	const inputs: Array<DefaultContactProperty> = [
		{ fieldKey: 'name', label: 'Name' },
		{ fieldKey: 'email', label: 'Email Address' },
		{ fieldKey: 'phone', label: 'Phone Number' },
		{ fieldKey: 'fax', label: 'Fax Number' },
	];

	const {
		handleSubmit,
		register,
		formState: { errors, isValid },
	} = useForm<Contact>({
		defaultValues: {
			name: defaultContact.name || '',
			email: defaultContact.email || '',
			phone: defaultContact.phone || '',
			fax: defaultContact.fax || '',
		},
		resolver: zodResolver(defaultContactFormSchema),
		mode: 'onBlur',
	});

	const onSubmit = (data: Contact) => {
		const contact: DefaultContactModel = { ...data, organizationId: activeEntity.id };
		dispatch(createProviderDefaultContact({ contact }));
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				{inputs.map((field, index) => {
					return (
						<Grid size={{ xs: 12 }} key={index}>
							<TextField
								{...register(field.fieldKey)}
								label={field.label}
								error={!!errors[field.fieldKey]}
								helperText={errors[field.fieldKey]?.message}
								sx={{
									minWidth: '350px',
								}}
							/>
						</Grid>
					);
				})}
				<Grid size={{ xs: 4 }}>
					<Button type="submit" startIcon={<PersonAddIcon />} disabled={!isValid}>
						Save Default Contact
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default DefaultContact;
