import {
	DashboardDetails,
	EmbedDashboardUrlResponse,
	EntityType,
	PartnerEntity,
} from 'type-declarations';
import { DashboardContentOptions } from 'amazon-quicksight-embedding-sdk';

export const getAndSortDashboardsFromEmbedUrls = (
	embedUrls: EmbedDashboardUrlResponse[]
): DashboardDetails[] => {
	return embedUrls
		.flatMap((embed) => embed.dashboards)
		.sort((a: DashboardDetails, b: DashboardDetails) => {
			return b.priority - a.priority;
		});
};

function getDashboardParameterName(partnerEntityType: string): string {
	switch (partnerEntityType) {
		case EntityType.DELEGATED_BENEFIT_MANAGER:
			return 'DelegatedBenefitManager';
		case EntityType.PAYER_INSTITUTION:
			return 'Payer2';
		case EntityType.PROVIDER_ORGANIZATION:
			return 'Provider2';
		default:
			return '';
	}
}

//We have to set default filters so they work correctly.
export function getDefaultContentOptions(
	activeEntity: PartnerEntity,
	selectedPartnerEntityIds: string[]
): DashboardContentOptions {
	return {
		parameters: [
			{
				Name: getDashboardParameterName(activeEntity.partnerType),
				Values: selectedPartnerEntityIds,
			},
		],
	};
}
