import { FC } from 'react';
import { Skeleton, Alert } from '@mui/material';
import { useAppSelector } from '@app/hooks/useReduxToolkit';

const LighthouseSkeletonLoaderAndErrorAlert: FC = () => {
	const { hasError } = useAppSelector((state) => state.lighthouse);

	return (
		<>
			{hasError ? (
				//If encountered error, show error message.
				<Alert severity="error">
					Lighthouse failed to load. Please reload the page. If the issue persists, contact support.
				</Alert>
			) : (
				//Else, display the skeleton loaders
				<>
					<Skeleton variant="text" sx={{ fontSize: '3rem' }} />
					<Skeleton variant="rounded" height={700} />
				</>
			)}
		</>
	);
};

export default LighthouseSkeletonLoaderAndErrorAlert;
