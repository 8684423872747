import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCaseHistory } from './thunks';
import { OrgHistoryModel, SliceStatus } from 'type-declarations';

export interface HistoryState {
	history: OrgHistoryModel;
	status: SliceStatus;
	isErrored: boolean;
}

const initialState: HistoryState = {
	history: {} as OrgHistoryModel,
	status: SliceStatus.IDLE,
	isErrored: false,
};

export const historySlice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		resetHistorySlice: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCaseHistory.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(
				fetchCaseHistory.fulfilled,
				(state, action: PayloadAction<OrgHistoryModel>) => {
					state.status = SliceStatus.SUCCEEDED;
					state.isErrored = false;
					state.history = action.payload;
				}
			),
			builder.addCase(fetchCaseHistory.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
				state.isErrored = true;
				state.history = {} as OrgHistoryModel;
			});
	},
});

export const historyReducer = historySlice.reducer;
export const historyActions = historySlice.actions;
export const { resetHistorySlice } = historyActions;
