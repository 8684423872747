import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Transaction } from '@sentry/react';

const initialState: SentryState = {
	transaction: {} as Transaction,
};

export interface SentryState {
	transaction: Transaction;
}

export const sentrySlice = createSlice({
	name: 'sentry',
	initialState,
	reducers: {
		saveSentryTransaction: (state: SentryState, action: PayloadAction<Transaction>) => {
			state.transaction = action.payload;
		},
		resetSentryTransaction: (state: SentryState) => {
			Object.assign(state, initialState);
		},
	},
});

export const sentryReducer = sentrySlice.reducer;
export const sentryActions = sentrySlice.actions;
export const { saveSentryTransaction, resetSentryTransaction } = sentryActions;
