import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, put } from 'aws-amplify/api';
import {
	Organization,
	PartnerEntity,
	RHYME_API_SERVICES,
	TermsOfService,
	TermsOfServiceAcceptance,
} from 'type-declarations';
import { convertOrgToEntity } from './utilities';
import { loadActiveEntityDetails } from 'state-services';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchOrganizations = createAsyncThunk(
	`organizationThunk/fetchOrganizations`,
	async ({ orgIds, isRso }: { orgIds: Array<string>; isRso: boolean }, { dispatch }) => {
		try {
			const organizations = await Promise.all(
				orgIds.map(async (id) => {
					const { body } = await get({
						apiName: rhymeApiUrl,
						path: `organization/${id}`,
					}).response;
					return (await body.json()) as unknown as Organization;
				})
			).catch((err) => {
				// eslint-disable-next-line no-console
				console.warn(err);
				return [] as Organization[];
			});
			//TODO: remove this when auth integration is done
			//hack to resovle not being able to call fetchPartners in saveUser method
			//***********************************************
			const entity = convertOrgToEntity(organizations[0]);
			isRso ? dispatch(loadActiveEntityDetails(entity as PartnerEntity)) : null;
			return organizations;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			return [] as Organization[];
		}
	}
);

export const fetchTermsOfService = createAsyncThunk(
	'organizationThunk/fetchTermsOfService',
	async () => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `terms`,
			}).response;
			const data = await body.json();
			return data as unknown as TermsOfService;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			throw new Error(`Fetch terms failed with error: ${error}`);
		}
	}
);

export const fetchUserTermsStatus = createAsyncThunk(
	'organizationThunk/fetchUserTermsStatus',
	async ({ orgId, userEmail }: { orgId: string; userEmail: string }, { dispatch }) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/user/${userEmail}/terms`,
			}).response;
			const data = (await body.json()) as unknown as TermsOfServiceAcceptance;
			if (!data.accepted) {
				dispatch(fetchTermsOfService());
			}
			return data.accepted;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			throw new Error(`fetch user terms failed with error: ${error}`);
		}
	}
);

export const postUserTermsStatus = createAsyncThunk(
	'organizationThunk/postUserTermsStatus',
	async ({
		orgId,
		userEmail,
		tosVersion,
	}: {
		orgId: string;
		userEmail: string;
		tosVersion: number;
	}) => {
		try {
			await put({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/user/${userEmail}/terms/version/${tosVersion}`,
			}).response;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			throw new Error(`Post user terms failed with error: ${error}`);
		}
	}
);
