import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const App: FC = () => {
	const navigate = useNavigate();

	const { activeEntity } = useAppSelector((state) => state.user);

	//on app load redirect to lighthouse
	useEffect(() => {
		activeEntity.isLighthouseEnabled ? navigate('/lighthouse') : navigate('/admin/users');
	}, []);

	return <></>;
};

export default App;
