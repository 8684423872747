// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/GEO_Pattern.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-amplify-authenticator]{background-color:#d6edd8;min-height:100vh;background:linear-gradient(rgba(214, 237, 216, 0.7), rgba(214, 237, 216, 0.9)),url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-size:cover;--amplify-components-authenticator-router-border-color: #383f5e;--amplify-components-authenticator-router-border-width: 2px}[data-amplify-authenticator] p{font-size:12px;margin-top:0}[data-amplify-container]{opacity:1}.amplify-button--primary{background:#2f5233}.amplify-button--link{color:#2f5233}.amplify-button--link:hover{color:#2f5233;background-color:#a0d6dc}`, "",{"version":3,"sources":["webpack://./../../shared/components/rhyme-component-library/src/components/authenticator/rhymeAuthenticator.scss"],"names":[],"mappings":"AAAA,6BACC,wBAAA,CACA,gBAAA,CACA,sHACC,CAED,2BAAA,CACA,qBAAA,CACA,+DAAA,CACA,2DAAA,CACA,+BACC,cAAA,CACA,YAAA,CAIF,yBACC,SAAA,CAGD,yBACC,kBAAA,CAGD,sBACC,aAAA,CAGD,4BACC,aAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
