import { Autocomplete, TextField } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldErrors, UseFormTrigger } from 'react-hook-form';
import {
	getDescriptiveRolesByPartnerEntity,
	getDescriptiveRolesByRoleStrings,
	PartnerEntity,
	RhymeUser,
} from 'type-declarations';

interface UserRoleAutcompleteProps {
	control: Control<RhymeUser>;
	errors: FieldErrors<any>;
	activeEntity: PartnerEntity;
	trigger: UseFormTrigger<RhymeUser>;
}

export const UserRoleAutcomplete: FC<UserRoleAutcompleteProps> = ({
	control,
	activeEntity,
	errors,
	trigger,
}) => {
	const roleOptions = activeEntity ? getDescriptiveRolesByPartnerEntity(activeEntity) : [];
	return (
		<Controller
			control={control}
			name="roles"
			render={({ field: { value, onChange, onBlur } }) => (
				<Autocomplete
					multiple
					options={roleOptions}
					getOptionLabel={(option) => option.display}
					onChange={(_event, values) => {
						onChange(values.map((v) => v.role as string));
						trigger('roles');
					}}
					value={getDescriptiveRolesByRoleStrings(value)}
					onBlur={() => onBlur()}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Roles"
							variant="outlined"
							error={!!errors.roles}
							helperText={(errors.roles?.message as string) ?? ''}
						/>
					)}
				/>
			)}
		/>
	);
};
