import { createSlice, SerializedError } from '@reduxjs/toolkit';
import { NoaRecord } from 'type-declarations';
import { fetchNoaSubmissionDetails, noaValidation, submitNoa } from './thunks';

export interface NoaState {
	noaSubmissionDetails: NoaRecord;
	hasError: boolean;
	errors: SerializedError[];
}

const initialState: NoaState = {
	noaSubmissionDetails: {} as NoaRecord,
	hasError: false,
	errors: [] as SerializedError[],
};

export const noaSlice = createSlice({
	name: 'noaThunk',
	initialState,
	reducers: {
		resetNoaSlice: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchNoaSubmissionDetails.pending, (state) => {
			state.hasError = false;
			state.errors = [];
		});
		builder.addCase(fetchNoaSubmissionDetails.fulfilled, (state, action) => {
			state.noaSubmissionDetails = action.payload;
		});
		builder.addCase(fetchNoaSubmissionDetails.rejected, (state, action) => {
			if (action.error.message !== 'Aborted') {
				state.hasError = true;
				state.errors = [...state.errors, action.error];
			}
		});
		builder.addCase(noaValidation.pending, (state) => {
			state.hasError = false;
			state.errors = [];
		});
		builder.addCase(noaValidation.rejected, (state, action) => {
			if (action.error.message !== 'Aborted') {
				state.hasError = true;
				state.errors = [...state.errors, action.error];
			}
		});
		builder.addCase(submitNoa.pending, (state) => {
			state.hasError = false;
			state.errors = [];
		});
		builder.addCase(submitNoa.rejected, (state, action) => {
			if (action.error.message !== 'Aborted') {
				state.hasError = true;
				state.errors = [...state.errors, action.error];
			}
		});
	},
});

export const noaReducer = noaSlice.reducer;
export const noaAction = noaSlice.actions;
export const { resetNoaSlice } = noaAction;
