import { ValidationError } from '@aws-amplify/ui-react/dist/types/components/AccountSettings/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, post } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import { RHYME_API_SERVICES, Referral } from 'type-declarations';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 424];

export const validatePreCheck = createAsyncThunk(
	'precheck-thunk/validatePreCheckThunk',
	async ({
		activeEntityId,
		partnerCaseId,
		referral,
	}: {
		activeEntityId: string;
		partnerCaseId: string;
		referral: Referral;
	}) => {
		const apiName = rhymeApiUrl;
		const path = `organization/${activeEntityId}/referral/${partnerCaseId}/precheck`;
		const options = {
			body: referral as unknown as FormData,
		};
		try {
			await post({ apiName, path, options }).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					const response = JSON.parse(body);
					return response.errors as ValidationError[];
				} else {
					return [] as ValidationError[];
				}
			}
			SnackbarManager.show({
				message: 'Failed to validate the case.',
				type: 'error',
			});
			return [] as ValidationError[];
		}
	}
);

export const validateAndSetDefaults = createAsyncThunk(
	'precheck-thunk/validateAndSetDefaultsThunk',
	async ({
		activeEntityId,
		partnerCaseId,
		referral,
	}: {
		activeEntityId: string;
		partnerCaseId: string;
		referral: Referral;
	}) => {
		const options = {
			body: referral as unknown as FormData,
		};
		try {
			return await post({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/draft/${partnerCaseId}/validate?setDefaults=true`,
				options,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				//when invalid service code is add to draft
				//the draft status updated to unsupported
				if (statusCode === 422) {
					return { status: statusCode };
				}
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        draft service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Validate and set precheck failed with error: ${error}`);
		}
	}
);
