import { createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "aws-amplify/api";
import { AdminTouchRequest, RHYME_API_SERVICES } from "type-declarations";

const rhymeApiService = RHYME_API_SERVICES.RhymeApi;

export const emitAdminTouchStarted = createAsyncThunk(
	'instrumentation-thunk/emitAdminTouchStarted',
	async ({
		orgId,
		partnerCaseId,
		request,
	}: {
		orgId: string;
		partnerCaseId: string;
		request: AdminTouchRequest;
	}) => {
		try {
			const options = {
				body: request as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/draft/${partnerCaseId}/instrument/admin-touch-started`,
				options,
			}).response;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
		}
	}
);

export const emitAdminTouchInteraction = createAsyncThunk(
	'instrumentation-thunk/emitAdminTouchInteraction',
	async ({
		orgId,
		partnerCaseId,
		request,
	}: {
		orgId: string;
		partnerCaseId: string;
		request: AdminTouchRequest;
	}) => {
		try {
			const options = {
				body: request as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/draft/${partnerCaseId}/instrument/admin-touch-interaction`,
				options,
			}).response;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
		}
	}
);

export const emitAdminTouchCompleted = createAsyncThunk(
	'instrumentation-thunk/emitAdminTouchCompleted',
	async ({
		orgId,
		partnerCaseId,
		request,
	}: {
		orgId: string;
		partnerCaseId: string;
		request: AdminTouchRequest;
	}) => {
		try {
			const options = {
				body: request as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/draft/${partnerCaseId}/instrument/admin-touch-completed`,
				options,
			}).response;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
		}
	}
);