import { PayerEntry } from 'services/dataGathering/preCheck/models';

export const PanPayerIdList: Array<PayerEntry> = [
	{ id: 'PAN.1199NB', name: `1199 National Benefit Fund` },
	{ id: 'PAN.AARP', name: `AARP Medicare Complete (UHC)` },
	{ id: 'PAN.ATCHC', name: `Absolute Total Care - Healthy Connections` },
	{ id: 'PAN.ABA', name: `Advantek Benefit Administrators` },
	{ id: 'PAN.AETNA', name: `Aetna` },
	{ id: 'PAN.ABH', name: `Aetna Better Health` },
	{ id: 'PAN.ABHCA', name: `Aetna Better Health of California` },
	{ id: 'PAN.ABHFL', name: `Aetna Better Health of Florida` },
	{ id: 'PAN.ABHIL', name: `Aetna Better Health of Illinois` },
	{ id: 'PAN.ABHKY', name: `Aetna Better Health of Kentucky` },
	{ id: 'PAN.ABHLA', name: `Aetna Better Health of Louisiana` },
	{ id: 'PAN.ABHMD', name: `Aetna Better Health of Maryland` },
	{ id: 'PAN.ABHMI', name: `Aetna Better Health of Michigan` },
	{ id: 'PAN.ABHNJ', name: `Aetna Better Health of New Jersey` },
	{ id: 'PAN.ABHOH', name: `Aetna Better Health of Ohio` },
	{ id: 'PAN.ABHPA', name: `Aetna Better Health of Pennsylvania` },
	{ id: 'PAN.ABHTX', name: `Aetna Better Health of Texas` },
	{ id: 'PAN.ABHVA', name: `Aetna Better Health of Virginia` },
	{ id: 'PAN.ABHWV', name: `Aetna Better Health of West Virginia` },
	{ id: 'PAN.AETNAMCR', name: `Aetna Medicare` },
	{ id: 'PAN.AHP', name: `Affinity Health Plan` },
	{ id: 'PAN.AGEWELL', name: `AgeWell New York Health Plans` },
	{ id: 'PAN.ALMCAID', name: `Alabama Medicaid` },
	{ id: 'PAN.AAFH', name: `Alameda Alliance for Health` },
	{ id: 'PAN.AKMCAID', name: `Alaska Medicaid` },
	{ id: 'PAN.ABPM', name: `Allegiance Benefit Plan Management` },
	{ id: 'PAN.ALLIANT', name: `Alliant Health Plans` },
	{ id: 'PAN.ABS', name: `Allied Benefit Systems` },
	{ id: 'PAN.ALLWAYS', name: `AllWays Health Partners` },
	{ id: 'PAN.ALLWELL', name: `Allwell` },
	{ id: 'PAN.AWATC', name: `Allwell from Absolute Total Care` },
	{ id: 'PAN.AWAZCH', name: `Allwell from Arizona Complete Health` },
	{ id: 'PAN.AWARHW', name: `Allwell from Arkansas Health & Wellness` },
	{ id: 'PAN.AWBHP', name: `Allwell from Buckeye Health Plan` },
	{ id: 'PAN.AWHSH', name: `Allwell from Home State Health` },
	{ id: 'PAN.AWICH', name: `Allwell from IlliniCare Health` },
	{ id: 'PAN.AWLHC', name: `Allwell from Louisiana Healthcare Connections` },
	{ id: 'PAN.AWMH', name: `Allwell from Magnolia Health` },
	{ id: 'PAN.AWMHSWI', name: `Allwell from MHS Health Wisconsin` },
	{ id: 'PAN.AWMHSIN', name: `Allwell from MHS Indiana` },
	{ id: 'PAN.AWPAHW', name: `Allwell from PA Health and Wellness` },
	{ id: 'PAN.AWPSHP', name: `Allwell from Peach State Health Plan` },
	{ id: 'PAN.AWSUNHP', name: `Allwell from Sunflower Health Plan` },
	{ id: 'PAN.AWSH', name: `Allwell from Sunshine Health` },
	{ id: 'PAN.AWSUPHP', name: `Allwell from Superior HealthPlan` },
	{ id: 'PAN.AWWSCC', name: `Allwell from Western Sky Community Care` },
	{ id: 'PAN.ALOHA', name: `AlohaCare` },
	{ id: 'PAN.AMB', name: `Ambetter` },
	{ id: 'PAN.AMBATC', name: `Ambetter from Absolute Total Care` },
	{ id: 'PAN.AMBAZCH', name: `Ambetter from Arizona Complete Health` },
	{ id: 'PAN.AMBARHW', name: `Ambetter from Arkansas Health & Wellness` },
	{ id: 'PAN.AMBBHP', name: `Ambetter from Buckeye Health Plan` },
	{ id: 'PAN.AMBCC', name: `Ambetter from Coordinated Care` },
	{ id: 'PAN.AMBHSH', name: `Ambetter from Home State Health` },
	{ id: 'PAN.AMBICH', name: `Ambetter from IlliniCare Health` },
	{ id: 'PAN.AMBMH', name: `Ambetter from Magnolia Health` },
	{ id: 'PAN.AMBMHS', name: `Ambetter from MHS` },
	{ id: 'PAN.AMBNHHF', name: `Ambetter from NH Healthy Families` },
	{ id: 'PAN.AMBPAHW', name: `Ambetter from PA Health and Wellness` },
	{ id: 'PAN.AMBPSHP', name: `Ambetter from Peach State Health Plan` },
	{ id: 'PAN.AMBSSHP', name: `Ambetter from SilverSummit Healthplan` },
	{ id: 'PAN.AMBSUNHP', name: `Ambetter from Sunflower Health Plan` },
	{ id: 'PAN.AMBSUNH', name: `Ambetter from Sunshine Health` },
	{ id: 'PAN.AMBSUPHP', name: `Ambetter from Superior HealthPlan` },
	{ id: 'PAN.AMBNC', name: `Ambetter of North Carolina` },
	{ id: 'PAN.AMBTN', name: `Ambetter of Tennessee` },
	{ id: 'PAN.AFCSC', name: `Americas 1st Choice of SC` },
	{ id: 'PAN.AMRGRP', name: `Amerigroup` },
	{ id: 'PAN.AMRGRPAZ', name: `Amerigroup Arizona` },
	{ id: 'PAN.AMRGRPDC', name: `Amerigroup D.C` },
	{ id: 'PAN.AMRGRPFL', name: `Amerigroup Florida` },
	{ id: 'PAN.AMRGRPGA', name: `Amerigroup Georgia` },
	{ id: 'PAN.AMRGRPIA', name: `Amerigroup Iowa` },
	{ id: 'PAN.AMRGRPMD', name: `Amerigroup Maryland` },
	{ id: 'PAN.AMRGRPNJ', name: `Amerigroup New Jersey` },
	{ id: 'PAN.AMRGRPNM', name: `Amerigroup New Mexico` },
	{ id: 'PAN.AMRGRPTN', name: `Amerigroup Tennessee` },
	{ id: 'PAN.AMRGRPTX', name: `Amerigroup Texas` },
	{ id: 'PAN.AMRGRPWA', name: `Amerigroup Washington` },
	{ id: 'PAN.AMHLTH', name: `AmeriHealth` },
	{ id: 'PAN.AMHLTHDC', name: `AmeriHealth Caritas D.C.` },
	{ id: 'PAN.AMHLTHDE', name: `AmeriHealth Caritas Delaware` },
	{ id: 'PAN.AMHLTHLA', name: `AmeriHealth Caritas Louisiana` },
	{ id: 'PAN.AMHLTHNE', name: `AmeriHealth Caritas Northeast` },
	{ id: 'PAN.AMHLTHPA', name: `AmeriHealth Caritas Pennsylvania` },
	{ id: 'PAN.AHCARVIP', name: `AmeriHealth Caritas VIP Care Plus` },
	{ id: 'PAN.AMHLTHNJ', name: `AmeriHealth of New Jersey` },
	{ id: 'PAN.AHLTHVIP', name: `AmeriHealth VIP Care` },
	{ id: 'PAN.AMIDA', name: `Amida Care` },
	{ id: 'PAN.ANTHEM', name: `Anthem` },
	{ id: 'PAN.ANTHASOC', name: `Anthem Associates` },
	{ id: 'PAN.ANTHEMCA', name: `Anthem BC of California` },
	{
		id: 'PAN.ANTHMHIP',
		name: `Anthem BCBS of IN Medicaid - Healthy Indiana Plan`,
	},
	{
		id: 'PAN.ANTHMHCC',
		name: `Anthem BCBS of IN Medicaid - Hoosier Care Connect`,
	},
	{
		id: 'PAN.ANTHMHHW',
		name: `Anthem BCBS of IN Medicaid - Hoosier Healthwise`,
	},
	{ id: 'PAN.ANTHEMMB', name: `Anthem MediBlue` },
	{ id: 'PAN.ANTHMMCR', name: `Anthem Medicare Advantage` },
	{
		id: 'PAN.ANMCRAIB',
		name: `Anthem Medicare Advantage - Individual Business`,
	},
	{ id: 'PAN.ANTHEMNA', name: `Anthem National` },
	{ id: 'PAN.ANTHEMGM', name: `Anthem National Accounts Auto GM and Chrysler` },
	{ id: 'PAN.ANTHEMCO', name: `Anthem of Colorado` },
	{ id: 'PAN.ANTHEMCT', name: `Anthem of Connecticut` },
	{ id: 'PAN.ANTHEMIN', name: `Anthem of Indiana` },
	{ id: 'PAN.ANTHEMKY', name: `Anthem of Kentucky` },
	{ id: 'PAN.ANKYMCAID', name: `Anthem of Kentucky Medicaid` },
	{ id: 'PAN.ANTHEMME', name: `Anthem of Maine` },
	{ id: 'PAN.ANTHEMMO', name: `Anthem of Missouri` },
	{ id: 'PAN.ANTHEMNV', name: `Anthem of Nevada` },
	{ id: 'PAN.ANNVMCAID', name: `Anthem of Nevada - Medicaid` },
	{ id: 'PAN.ANTHEMNH', name: `Anthem of New Hampshire` },
	{ id: 'PAN.ANTHEMOH', name: `Anthem of Ohio` },
	{ id: 'PAN.ANTHEMVA', name: `Anthem of Virginia` },
	{ id: 'PAN.ANVAHKP', name: `Anthem of Virginia HealthKeepers Plus` },
	{ id: 'PAN.ANTHEMWI', name: `Anthem of Wisconsin` },
	{ id: 'PAN.ANWIMCAID', name: `Anthem of Wisconsin Medicaid` },
	{ id: 'PAN.APWUUHC', name: `APWU Consumer Driven UHC` },
	{ id: 'PAN.APWU', name: `APWU Health Plan` },
	{ id: 'PAN.APWUCI', name: `APWU High Option Cigna` },
	{ id: 'PAN.ARISE', name: `Arise Health Plan` },
	{ id: 'PAN.AZCH', name: `Arizona Complete Health` },
	{ id: 'PAN.AZMCAID', name: `Arizona Medicaid` },
	{ id: 'PAN.ARHW', name: `Arkansas Health & Wellness` },
	{ id: 'PAN.ARMCAID', name: `Arkansas Medicaid` },
	{ id: 'PAN.ARTC', name: `Arkansas Total Care` },
	{ id: 'PAN.ASURISNW', name: `Asuris Northwest Health` },
	{ id: 'PAN.ATRIO', name: `ATRIO Health Plans` },
	{ id: 'PAN.AULTCARE', name: `AultCare` },
	{ id: 'PAN.AUTOBS', name: `Automated Benefit Services` },
	{ id: 'PAN.AVERA', name: `Avera Health Plans` },
	{ id: 'PAN.AVMED', name: `AvMed` },
	{ id: 'PAN.BHN', name: `Banner Health Network` },
	{ id: 'PAN.BCID', name: `BC of Idaho` },
	{ id: 'PAN.BCBSBCNMI', name: `BCBS Blue Care Network of Michigan` },
	{ id: 'PAN.BCBSFEPIL', name: `BCBS FEP - Illinois` },
	{ id: 'PAN.BCBSFEPTX', name: `BCBS FEP - Texas` },
	{ id: 'PAN.BCBSAL', name: `BCBS of Alabama` },
	{ id: 'PAN.BCBSAZ', name: `BCBS of Arizona` },
	{ id: 'PAN.BCBSAR', name: `BCBS of Arkansas` },
	{ id: 'PAN.BCBSGA', name: `BCBS of Georgia` },
	{ id: 'PAN.BCBSIL', name: `BCBS of Illinois` },
	{ id: 'PAN.BCBSILGOV', name: `BCBS of Illinois` },
	{ id: 'PAN.BCBSILCH', name: `BCBS of Illinois Community Health Plan` },
	{ id: 'PAN.BCBSILMCR', name: `BCBS of Illinois Medicare PPO` },
	{ id: 'PAN.BCBSILPPO', name: `BCBS of Illinois PPO` },
	{
		id: 'PAN.BCBSILBCS',
		name: `BCBS of Illinois PPO - Blue Choice Select PPO`,
	},
	{
		id: 'PAN.BCBSILBO',
		name: `BCBS of Illinois PPO - Blue Options (Small Groups)`,
	},
	{ id: 'PAN.BCBSILBS', name: `BCBS of Illinois PPO - Blue Security PPO` },
	{ id: 'PAN.BCBSILBC', name: `BCBS of Illinois PPO - BlueChoice PPO` },
	{ id: 'PAN.BCBSILBCP', name: `BCBS of Illinois PPO - BlueChoice Preferred` },
	{
		id: 'PAN.BCBSILBCSVC',
		name: `BCBS of Illinois PPO - BlueChoice Select Value Choice`,
	},
	{ id: 'PAN.BCBSILHCA', name: `BCBS of Illinois PPO - BlueEdge HCA` },
	{ id: 'PAN.BCBSILHSA', name: `BCBS of Illinois PPO - BlueEdge HSA` },
	{ id: 'PAN.BCBSILBP', name: `BCBS of Illinois PPO - BluePrint` },
	{
		id: 'PAN.BCBSILHASBCO',
		name: `BCBS of Illinois PPO - Health Advocacy Solutions Blue Choice Options PPO`,
	},
	{
		id: 'PAN.BCBSILHASBCS',
		name: `BCBS of Illinois PPO - Health Advocacy Solutions Blue Choice Select PPO`,
	},
	{
		id: 'PAN.BCBSILHAS',
		name: `BCBS of Illinois PPO - Health Advocacy Solutions PPO`,
	},
	{ id: 'PAN.BCBSILPVC', name: `BCBS of Illinois PPO - PPO Value Choice` },
	{
		id: 'PAN.BCBSILWBCO',
		name: `BCBS of Illinois PPO - Wellbeing Management Blue Choice Options PPO`,
	},
	{
		id: 'PAN.BCBSILWBCS',
		name: `BCBS of Illinois PPO - Wellbeing Management Blue Choice Select PPO`,
	},
	{
		id: 'PAN.BCBSILWCMM',
		name: `BCBS of Illinois PPO - Wellbeing Management Comprehensive Major Medical`,
	},
	{
		id: 'PAN.BCBSILWM',
		name: `BCBS of Illinois PPO - Wellbeing Management PPO`,
	},
	{ id: 'PAN.BCBSKS', name: `BCBS of Kansas` },
	{ id: 'PAN.BCBSKC', name: `BCBS of Kansas City` },
	{ id: 'PAN.BCBSLA', name: `BCBS of Louisiana` },
	{ id: 'PAN.BCBSLAGB', name: `BCBS of Louisiana - Office of Group Benefits` },
	{ id: 'PAN.BCBSMA', name: `BCBS of Massachusetts` },
	{ id: 'PAN.BCBSMAA', name: `BCBS of Massachusetts Associates` },
	{ id: 'PAN.BCBSMAMA', name: `BCBS of Massachusetts Medicare Advantage` },
	{ id: 'PAN.BCBSMI', name: `BCBS of Michigan` },
	{ id: 'PAN.BCBSMN', name: `BCBS of Minnesota` },
	{ id: 'PAN.BCBSMS', name: `BCBS of Mississippi` },
	{ id: 'PAN.BCBSMT', name: `BCBS of Montana` },
	{ id: 'PAN.BCBSNE', name: `BCBS of Nebraska` },
	{ id: 'PAN.BCBSNM', name: `BCBS of New Mexico` },
	{ id: 'PAN.BCBSNC', name: `BCBS of North Carolina` },
	{
		id: 'PAN.BCBSNCSHPM',
		name: `BCBS of North Carolina - State Health Plan Members`,
	},
	{ id: 'PAN.BCBSND', name: `BCBS of North Dakota` },
	{ id: 'PAN.BCBSOK', name: `BCBS of Oklahoma` },
	{ id: 'PAN.BCBSRI', name: `BCBS of Rhode Island` },
	{ id: 'PAN.BCBSSC', name: `BCBS of South Carolina` },
	{ id: 'PAN.BCBSTN', name: `BCBS of Tennessee` },
	{ id: 'PAN.BCBSTNBAP', name: `BCBS of Tennessee - BlueAdvantage Plus PPO` },
	{ id: 'PAN.BCBSTNBA', name: `BCBS of Tennessee - BlueAdvantage PPO` },
	{ id: 'PAN.BCBSTNHMO', name: `BCBS of Tennessee - BlueCare Plus HMO-SNP` },
	{ id: 'PAN.BCBSTNBC', name: `BCBS of Tennessee - BlueCare` },
	{ id: 'PAN.BCBSTNCK', name: `BCBS of Tennessee - CoverKids` },
	{ id: 'PAN.BCBSTNTCS', name: `BCBS of Tennessee - TennCare Select` },
	{ id: 'PAN.BCBSTX', name: `BCBS of Texas` },
	{
		id: 'PAN.BCBSTXPFI',
		name: `BCBS of Texas - Blue Choice PPO Fully Insured`,
	},
	{
		id: 'PAN.BCBSTXSIHAS',
		name: `BCBS of Texas - Blue Choice PPO Self Insured with Health Advocacy Solutions`,
	},
	{
		id: 'PAN.BCBSTXSIHASA',
		name: `BCBS of Texas - Blue Choice PPO Self Insured with Health Advocacy Solutions Advanced`,
	},
	{
		id: 'PAN.BCBSTXSIHASP',
		name: `BCBS of Texas - Blue Choice PPO Self Insured with Health Advocacy Solutions Premier`,
	},
	{
		id: 'PAN.BCBSTXSIWOHAS',
		name: `BCBS of Texas - Blue Choice PPO Self Insured without Health Advocacy Solutions`,
	},
	{
		id: 'PAN.BCBSTXEA',
		name: `BCBS of Texas - Blue Essentials and Blue Essentials Access`,
	},
	{ id: 'PAN.BCBSTXMA', name: `BCBS of Texas - Medicare Advantage PPO` },
	{ id: 'PAN.BCBSTXSC', name: `BCBS of Texas - Star and Chip` },
	{ id: 'PAN.BCBSTXSKM', name: `BCBS of Texas - Star Kids Medicaid` },
	{
		id: 'PAN.BCBSTXMCAREA',
		name: `BCBS of Texas - Medicare Advantage HMO & SNP Plans`,
	},
	{ id: 'PAN.BCBSVT', name: `BCBS of Vermont` },
	{ id: 'PAN.BCBSVTCBA', name: `BCBS of Vermont - CBA` },
	{ id: 'PAN.BCBSWNY', name: `BCBS of Western New York` },
	{ id: 'PAN.BCBSWNYMCAID', name: `BCBS of Western New York - Medicaid` },
	{ id: 'PAN.BCBSWY', name: `BCBS of Wyoming` },
	{ id: 'PAN.BLCRSS', name: `Blue Cross` },
	{ id: 'PAN.BLSHLD', name: `Blue Shield` },
	{ id: 'PAN.BSCA', name: `Blue Shield of California` },
	{ id: 'PAN.BSCAPHP', name: `Blue Shield of California Promise Health Plan` },
	{ id: 'PAN.BADAAR', name: `BlueAdvantage Administrators of Arkansas` },
	{ id: 'PAN.BCHPSC', name: `BlueChoice HealthPlan of South Carolina` },
	{ id: 'PAN.BCBS', name: `BlueCross BlueShield` },
	{ id: 'PAN.BCBSFEP', name: `BlueCross BlueShield Federal Employee Plan` },
	{ id: 'PAN.BSNENY', name: `BlueShield of Northeastern New York` },
	{ id: 'PAN.BMCHNP', name: `Boston Medical Center HealthNet Plan` },
	{ id: 'PAN.BSPH', name: `BridgeSpan Health` },
	{ id: 'PAN.BRTH', name: `Bright Health` },
	{ id: 'PAN.BKEYEHP', name: `Buckeye Health Plan` },
	{ id: 'PAN.CALHW', name: `California Health & Wellness` },
	{ id: 'PAN.CALOPT', name: `CalOptima` },
	{ id: 'PAN.CAPBC', name: `Capital BC` },
	{ id: 'PAN.CAPHP', name: `Captial Health Plan` },
	{ id: 'PAN.CIPSC', name: `Care Improvement Plus South Central` },
	{ id: 'PAN.C1HPAZ', name: `Care1st Health Plan of Arizona` },
	{ id: 'PAN.CFBCBS', name: `CareFirst BCBS` },
	{ id: 'PAN.CAREMO', name: `CareMore Health - Cal MediConnect` },
	{ id: 'PAN.CAREOR', name: `CareOregon Advantage` },
	{ id: 'PAN.CAREPLHP', name: `CarePlus Health Plans` },
	{ id: 'PAN.CARESO', name: `CareSource` },
	{ id: 'PAN.CARESOGA', name: `CareSource of Georgia` },
	{ id: 'PAN.CARESOIN', name: `CareSource of Indiana` },
	{ id: 'PAN.CARESOKY', name: `CareSource of Kentucky - Humana` },
	{ id: 'PAN.CARESOOH', name: `CareSource of Ohio` },
	{ id: 'PAN.CARSOWVA', name: `CareSource of West Virginia` },
	{ id: 'PAN.CDPHP', name: `CDPHP - Capital District Physicians Health Plan` },
	{ id: 'PAN.CENCAL', name: `CenCal Health` },
	{ id: 'PAN.CCAFH', name: `Central California Alliance For Health` },
	{ id: 'PAN.CHMP', name: `Central Health Medicare Plan` },
	{ id: 'PAN.CCHP', name: `Children's Community Health Plan` },
	{ id: 'PAN.CHRISTUS', name: `CHRISTUS Health` },
	{
		id: 'PAN.CHRSTUSLA',
		name: `CHRISTUS Health - Louisiana Family and Individual Plans`,
	},
	{ id: 'PAN.CHRSTUSMCR', name: `CHRISTUS Health - Medicare Advantage` },
	{
		id: 'PAN.CHRSTUSNM',
		name: `CHRISTUS Health - New Mexico Family and Individual Plans`,
	},
	{ id: 'PAN.CHRSTUSTX', name: `CHRISTUS Health - Texas HIX` },
	{ id: 'PAN.CHRISTUSFHP', name: `CHRISTUS Health - US Family Health Plan` },
	{ id: 'PAN.CIGNA', name: `Cigna` },
	{ id: 'PAN.CIGNAHS', name: `Cigna HealthSpring` },
	{ id: 'PAN.CLEARA', name: `Clear Alliance` },
	{ id: 'PAN.COACC', name: `Colorado Access` },
	{ id: 'PAN.COMCAID', name: `Colorado Medicaid` },
	{ id: 'PAN.CMWCA', name: `Commonwealth Care Alliance` },
	{ id: 'PAN.COMHC', name: `Community Health Choice` },
	{ id: 'PAN.COMHG', name: `Community Health Group` },
	{ id: 'PAN.COMHPW', name: `Community Health Plan of Washington` },
	{
		id: 'PAN.COMHFMCR',
		name: `Community HealthFirst Medicare Advantage Plans`,
	},
	{ id: 'PAN.COMCAR', name: `CommunityCare` },
	{ id: 'PAN.CTCARE', name: `ConnectiCare` },
	{ id: 'PAN.CTCAREMA', name: `ConnectiCare Medicare Advantage` },
	{ id: 'PAN.CTCAREMCAID', name: `Connecticut Medicaid` },
	{ id: 'PAN.COCOHP', name: `Contra Costa Health Plan` },
	{ id: 'PAN.COOKCH', name: `Cook Children's Health Plan` },
	{ id: 'PAN.COORDCARE', name: `Coordinated Care` },
	{ id: 'PAN.COUNTY', name: `CountyCare` },
	{ id: 'PAN.COVHC', name: `Coventry Health Care` },
	{ id: 'PAN.COVHCDE', name: `Coventry Health Care of Delaware` },
	{ id: 'PAN.COVHCFL', name: `Coventry Health Care of Florida` },
	{ id: 'PAN.COVHCGA', name: `Coventry Health Care of Georgia` },
	{ id: 'PAN.COVHCIL', name: `Coventry Health Care of Illinois` },
	{ id: 'PAN.COVHCIA', name: `Coventry Health Care of Iowa` },
	{ id: 'PAN.COVHCKS', name: `Coventry Health Care of Kansas` },
	{ id: 'PAN.COVHCMO', name: `Coventry Health Care of Missouri` },
	{ id: 'PAN.COVHCNE', name: `Coventry Health Care of Nebraska` },
	{ id: 'PAN.COVHCOH', name: `Coventry Health Care of Ohio` },
	{ id: 'PAN.COVHCOK', name: `Coventry Health Care of Oklahoma` },
	{ id: 'PAN.COVHCPA', name: `Coventry Health Care of Pennsylvania` },
	{ id: 'PAN.COVHCSD', name: `Coventry Health Care of South Dakota` },
	{ id: 'PAN.COVHCCAR', name: `Coventry Health Care of the Carolinas` },
	{ id: 'PAN.COVHCUT', name: `Coventry Health Care of Utah` },
	{ id: 'PAN.COVHCWV', name: `Coventry Health Care of West Virginia` },
	{ id: 'PAN.COVHCVA', name: `Coventry Health Care Virginia` },
	{ id: 'PAN.COXHP', name: `Cox HealthPlans` },
	{ id: 'PAN.DAKCARE', name: `DAKOTACARE` },
	{ id: 'PAN.DEANHP', name: `Dean Health Plan` },
	{ id: 'PAN.DEMCAID', name: `Delaware Medicaid` },
	{ id: 'PAN.DENHMP', name: `Denver Health Medical Plan` },
	{ id: 'PAN.DCMCAID', name: `District of Columbia Medicaid` },
	{ id: 'PAN.EMBLEM', name: `EmblemHealth` },
	{ id: 'PAN.EMPIRE', name: `Empire BCBS` },
	{ id: 'PAN.ESSENCE', name: `Essence Healthcare` },
	{ id: 'PAN.EXCELLUS', name: `Excellus BCBS` },
	{ id: 'PAN.EXCLMCAID', name: `Excellus BCBS Medicaid` },
	{ id: 'PAN.FALCHP', name: `Fallon Community Health Plan` },
	{ id: 'PAN.FIDCARE', name: `Fidelis Care` },
	{ id: 'PAN.FIDSLMCAID', name: `Fidelis SecureLife Medicaid` },
	{ id: 'PAN.FIRSTHN', name: `First Health Network` },
	{ id: 'PAN.FCSHSC', name: `FirstChoice by Select Health of South Carolina` },
	{ id: 'PAN.FLBLUE', name: `Florida Blue` },
	{ id: 'PAN.FHCP', name: `Florida Health Care Plans (FHCP)` },
	{ id: 'PAN.FLHEAL', name: `Florida Health Plans` },
	{ id: 'PAN.FLMCAID', name: `Florida Medicaid` },
	{ id: 'PAN.FRESHP', name: `Fresenius Health Plans` },
	{ id: 'PAN.GATEWAY', name: `Gateway Health Plan` },
	{ id: 'PAN.GEISINGER', name: `Geisinger Health Plan` },
	{ id: 'PAN.GAMCAID', name: `Georgia Medicaid` },
	{ id: 'PAN.GLOBAL', name: `GlobalHealth` },
	{ id: 'PAN.GOLDCHP', name: `Gold Coast Health Plan` },
	{ id: 'PAN.GOLDEN', name: `Golden Rule Insurance Company` },
	{ id: 'PAN.GEHA', name: `Government Employee Health Association (GEHA)` },
	{
		id: 'PAN.GHCSCW',
		name: `Group Health Cooperative South Central Wisconsin`,
	},
	{ id: 'PAN.GUNDER', name: `Gundersen Health Plan` },
	{ id: 'PAN.HARVPHC', name: `Harvard Pilgrim Health Care` },
	{ id: 'PAN.HIMA', name: `Hawaii Mainland Administrators` },
	{ id: 'PAN.HIMCAID', name: `Hawaii Medicaid` },
	{ id: 'PAN.HABCBSAR', name: `Health Advantage BCBS - Arkansas` },
	{ id: 'PAN.HA', name: `Health Alliance` },
	{
		id: 'PAN.HAPEMIMCAID',
		name: `Health Alliance Plan Empowered Michigan Medicaid`,
	},
	{ id: 'PAN.HAPMI', name: `Health Alliance Plan of Michigan` },
	{ id: 'PAN.HCAZ', name: `Health Choice Arizona` },
	{ id: 'PAN.HFCO', name: `Health First Colorado` },
	{ id: 'PAN.HFHP', name: `Health First Health Plans` },
	{ id: 'PAN.HFNY', name: `Health First of New York` },
	{ id: 'PAN.HNET', name: `Health Net` },
	{ id: 'PAN.HNETFSTW', name: `Health Net Federal Services – TRICARE West` },
	{ id: 'PAN.HNE', name: `Health New England` },
	{ id: 'PAN.HPP', name: `Health Partners Plans` },
	{ id: 'PAN.HPNV', name: `Health Plan of Nevada` },
	{ id: 'PAN.HPSJ', name: `Health Plan of San Joaquin` },
	{ id: 'PAN.HPSM', name: `Health Plan of San Mateo` },
	{ id: 'PAN.HCOK', name: `HealthChoice of Oklahoma` },
	{ id: 'PAN.OMIT', name: `Healthfirst Health Plans` },
	{ id: 'PAN.HLINK', name: `HealthLink` },
	{ id: 'PAN.HPART', name: `HealthPartners` },
	{ id: 'PAN.HPLUS', name: `HealthPlus` },
	{ id: 'PAN.HSCOPE', name: `HealthSCOPE Benefits` },
	{ id: 'PAN.HUOFU', name: `Healthy U of Utah` },
	{ id: 'PAN.HIGHMK', name: `Highmark BCBS` },
	{ id: 'PAN.HMSAHI', name: `HMSA BCBS of Hawaii` },
	{ id: 'PAN.HOMESHP', name: `Home State Health Plan` },
	{ id: 'PAN.HRZNNJ', name: `Horizon BCBS of New Jersey` },
	{ id: 'PAN.HRZNNJNASC', name: `Horizon BCBS of New Jersey - NASCO` },
	{ id: 'PAN.HRZNNJHL', name: `Horizon NJ Health` },
	{ id: 'PAN.HUMANA', name: `Humana` },
	{ id: 'PAN.HMTE', name: `Humana Military - TRICARE East` },
	{ id: 'PAN.HUSKY', name: `Husky Health Connecticut` },
	{ id: 'PAN.IDMCAID', name: `Idaho Medicaid` },
	{ id: 'PAN.ILCH', name: `Illinicare Health` },
	{ id: 'PAN.ILMCAID', name: `Illinois Medicaid` },
	{ id: 'PAN.IMCARE', name: `IMCare` },
	{ id: 'PAN.INDPBC', name: `Independence Blue Cross` },
	{ id: 'PAN.INDPH', name: `Independent Health` },
	{ id: 'PAN.INMCAID', name: `Indiana Medicaid` },
	{ id: 'PAN.INEMPIRE', name: `Inland Empire Health Plan` },
	{ id: 'PAN.INNHEALTH', name: `Innovation Health` },
	{ id: 'PAN.IVHP', name: `Inter Valley Health Plan` },
	{ id: 'PAN.IAMCAID', name: `Iowa Medicaid` },
	{ id: 'PAN.IUHP', name: `IU Health Plans` },
	{ id: 'PAN.JAIMED', name: `Jai Medical` },
	{ id: 'PAN.JOHNHO', name: `Johns Hopkins HealthCare` },
	{ id: 'PAN.KAISER', name: `Kaiser Permanente` },
	{ id: 'PAN.KSMCAID', name: `Kansas Medicaid` },
	{ id: 'PAN.KYMCAID', name: `Kentucky Medicaid` },
	{ id: 'PAN.KEYFIR', name: `Keystone First` },
	{ id: 'PAN.KFCHC', name: `Keystone First Community HealthChoices` },
	{ id: 'PAN.LACARE', name: `LA Care Health Plan` },
	{ id: 'PAN.LWOR', name: `LifeWise of Oregon` },
	{ id: 'PAN.LWWA', name: `LifeWise of Washington` },
	{ id: 'PAN.LAHCC', name: `Louisiana Healthcare Connections` },
	{ id: 'PAN.LAMCAID', name: `Louisiana Medicaid` },
	{ id: 'PAN.MAGCC', name: `Magellan Complete Care` },
	{ id: 'PAN.MAGNA', name: `MagnaCare` },
	{ id: 'PAN.MAGH', name: `Magnolia Health` },
	{ id: 'PAN.MECOMHO', name: `Maine Community Health Options` },
	{ id: 'PAN.MEMCAID', name: `Maine Medicaid` },
	{ id: 'PAN.MPGA', name: `Martins Point Generations Advantage` },
	{ id: 'PAN.MDMCAID', name: `Maryland Medicaid` },
	{ id: 'PAN.MDPC', name: `Maryland Physician Care` },
	{ id: 'PAN.MAMCAID', name: `Massachusetts Medicaid` },
	{ id: 'PAN.MDWHIP', name: `MDWise - Healthy Indiana Plan` },
	{ id: 'PAN.MDWHH', name: `MDWise - Hoosier Healthwise` },
	{ id: 'PAN.MEDIHP', name: `Medica Health Plan` },
	{ id: 'PAN.MEDIHC', name: `Medica HealthCare` },
	{ id: 'PAN.CAMCAID', name: `Medi-Cal` },
	{ id: 'PAN.MEDAHP', name: `Medical Associates Health Plans` },
	{ id: 'PAN.MMOH', name: `Medical Mutual of Ohio` },
	{ id: 'PAN.MCARE', name: `Medicare` },
	{ id: 'PAN.MEDIGOLD', name: `MediGold` },
	{ id: 'PAN.MSFC', name: `MedStar Family Choice` },
	{ id: 'PAN.MSMCARE', name: `MedStar Medicare Choice` },
	{ id: 'PAN.MERCYCARE', name: `Mercy Care` },
	{ id: 'PAN.MCPAZ', name: `Mercy Care Plan - Arizona` },
	{ id: 'PAN.MMIC', name: `Mercy Maricopa Integrated Care` },
	{ id: 'PAN.MERIDH', name: `Meridian Health` },
	{ id: 'PAN.MERITAIN', name: `Meritain Health` },
	{ id: 'PAN.METROP', name: `MetroPlus Health Plan` },
	{ id: 'PAN.MHSIN', name: `MHS (Managed Health Services of Indiana)` },
	{ id: 'PAN.MHSWI', name: `MHS (Managed Health Services of Wisconsin)` },
	{ id: 'PAN.MICH', name: `Michigan Complete Health` },
	{ id: 'PAN.MIMCAID', name: `Michigan Medicaid` },
	{ id: 'PAN.MIDATLR', name: `Mid-Atlantic Regional` },
	{ id: 'PAN.MNMCAID', name: `Minnesota Medicaid` },
	{ id: 'PAN.MNCARE', name: `MinnesotaCare` },
	{ id: 'PAN.MSMCAID', name: `Mississippi Medicaid` },
	{ id: 'PAN.MOMCAID', name: `Missouri Medicaid` },
	{ id: 'PAN.MODA', name: `Moda Health` },
	{ id: 'PAN.MOLINA', name: `Molina Healthcare` },
	{ id: 'PAN.MOLINACA', name: `Molina Healthcare of California` },
	{ id: 'PAN.MOLINAFL', name: `Molina Healthcare of Florida` },
	{ id: 'PAN.MOLINAID', name: `Molina Healthcare of Idaho` },
	{ id: 'PAN.MOLINAIL', name: `Molina Healthcare of Illinois` },
	{ id: 'PAN.MOLINAMI', name: `Molina Healthcare of Michigan` },
	{ id: 'PAN.MOLINAMS', name: `Molina Healthcare of Mississippi` },
	{ id: 'PAN.MOLINANM', name: `Molina Healthcare of New Mexico` },
	{ id: 'PAN.MOLINANY', name: `Molina Healthcare of New York` },
	{ id: 'PAN.MOLINAOH', name: `Molina Healthcare of Ohio` },
	{ id: 'PAN.MOLINAPR', name: `Molina Healthcare of Puerto Rico` },
	{ id: 'PAN.MOLINASC', name: `Molina Healthcare of South Carolina` },
	{ id: 'PAN.MOLINATX', name: `Molina Healthcare of Texas` },
	{ id: 'PAN.MOLINAUT', name: `Molina Healthcare of Utah` },
	{ id: 'PAN.MOLINAWA', name: `Molina Healthcare of Washington` },
	{ id: 'PAN.MOLINAWI', name: `Molina Healthcare of Wisconsin` },
	{ id: 'PAN.MTHC', name: `Montana Health Co-op` },
	{ id: 'PAN.MTMCAID', name: `Montana Medicaid` },
	{ id: 'PAN.MVPHC', name: `MVP Health Care` },
	{ id: 'PAN.NALC', name: `National Association of Letter Carriers (NALC)` },
	{ id: 'PAN.NEMCAID', name: `Nebraska Medicaid` },
	{ id: 'PAN.NETC', name: `Nebraska Total Care` },
	{ id: 'PAN.NHHPRI', name: `Neighborhood Health Plan of Rhode Island` },
	{ id: 'PAN.NETWRK', name: `Network Health` },
	{ id: 'PAN.NVMCAID', name: `Nevada Medicaid` },
	{ id: 'PAN.NHHF', name: `New Hampshire Healthy Families` },
	{ id: 'PAN.NHMCAID', name: `New Hampshire Medicaid` },
	{ id: 'PAN.NJMCAID', name: `New Jersey Medicaid` },
	{ id: 'PAN.NMHC', name: `New Mexico Health Connections` },
	{ id: 'PAN.NMMCAID', name: `New Mexico Medicaid` },
	{ id: 'PAN.NYMCAID', name: `New York Medicaid` },
	{ id: 'PAN.NXTLEV', name: `NextLevelHealth` },
	{ id: 'PAN.NIPPON', name: `Nippon Life Insurance Company` },
	{ id: 'PAN.NCMCAID', name: `North Carolina Medicaid` },
	{ id: 'PAN.NDMCAID', name: `North Dakota Medicaid` },
	{ id: 'PAN.NOVA', name: `Nova Healthcare Administrators` },
	{ id: 'PAN.OHMCAID', name: `Ohio Medicaid` },
	{ id: 'PAN.OKMCAID', name: `Oklahoma Medicaid` },
	{ id: 'PAN.ONENET', name: `OneNet PPO Supplement` },
	{ id: 'PAN.OPTIMA', name: `Optima Health Plan` },
	{ id: 'PAN.ORMCAID', name: `Oregon Medicaid` },
	{ id: 'PAN.OSCAR', name: `Oscar` },
	{ id: 'PAN.OXHP', name: `Oxford Health Plan (UHC)` },
	{ id: 'PAN.PAHW', name: `PA Health and Wellness` },
	{ id: 'PAN.PACSHP', name: `PacificSource Health Plans` },
	{ id: 'PAN.PARAMT', name: `Paramount` },
	{ id: 'PAN.PARTHP', name: `Partnership Health Plan` },
	{ id: 'PAN.PASSPORT', name: `Passport Health Plan` },
	{ id: 'PAN.PEACHSHP', name: `Peach State Health Plan` },
	{ id: 'PAN.PAMCAID', name: `Pennsylvania Medicaid` },
	{ id: 'PAN.PEOPLES', name: `Peoples Health Network` },
	{ id: 'PAN.PHYSHP', name: `Physicians Health Plan` },
	{ id: 'PAN.PHYSPLUS', name: `Physicians Plus` },
	{ id: 'PAN.PCP', name: `Preferred Care Partners` },
	{ id: 'PAN.PREFONE', name: `PreferredOne` },
	{ id: 'PAN.PREMBC', name: `Premera Blue Cross` },
	{ id: 'PAN.PREMHP', name: `Premier Health Plan` },
	{ id: 'PAN.PRESBT', name: `Presbyterian` },
	{ id: 'PAN.PRESBTCC', name: `Presbyterian Centennial Care` },
	{ id: 'PAN.PRESBTHP', name: `Presbyterian Health Plan` },
	{ id: 'PAN.PRESTI', name: `Prestige Health Choice` },
	{ id: 'PAN.PRIORPAR', name: `Priority Partners` },
	{ id: 'PAN.PRIORH', name: `PriorityHealth` },
	{ id: 'PAN.PROMHP', name: `Prominence Health Plan` },
	{ id: 'PAN.PROMHFTX', name: `Prominence HealthFirst of TX` },
	{ id: 'PAN.PROVHP', name: `Providence Health Plan` },
	{ id: 'PAN.PROVPHP', name: `Provider Partners Health Plan` },
	{ id: 'PAN.PEHP', name: `Public Employee Health Plan (PEHP)` },
	{ id: 'PAN.QUALCA', name: `QualCare` },
	{ id: 'PAN.QUALCH', name: `QualChoice` },
	{ id: 'PAN.QUARTZ', name: `Quartz` },
	{ id: 'PAN.REGENCE', name: `Regence BCBS` },
	{ id: 'PAN.REGENCEOR', name: `Regence BCBS - Oregon` },
	{ id: 'PAN.REGENCEWA', name: `Regence BCBS - Washington` },
	{ id: 'PAN.REGENCEAD', name: `Regence Group Administrators` },
	{ id: 'PAN.RIMCAID', name: `Rhode Island Medicaid` },
	{ id: 'PAN.RIGHTC', name: `RightCare` },
	{ id: 'PAN.RIVVAL', name: `River Valley Entities Supplement` },
	{ id: 'PAN.ROCKYM', name: `Rocky Mountain Health Plans` },
	{ id: 'PAN.SAMHP', name: `Samaritan Health Plans` },
	{ id: 'PAN.SFHP', name: `San Francisco Health Plan` },
	{ id: 'PAN.SANFORD', name: `Sanford Health Plan` },
	{ id: 'PAN.SCFHP', name: `Santa Clara Family Health Plan` },
	{ id: 'PAN.SCAN', name: `SCAN Health Plan` },
	{ id: 'PAN.SWHP', name: `Scott And White Health Plan` },
	{ id: 'PAN.SECHP', name: `Security Health Plan` },
	{ id: 'PAN.SLCTHL', name: `SelectHealth` },
	{ id: 'PAN.SHCCUT', name: `SelectHealth Community Care of Utah` },
	{ id: 'PAN.SWHNY', name: `Senior Whole Health of NY` },
	{ id: 'PAN.SHARP', name: `Sharp Health Plan` },
	{ id: 'PAN.SIERRA', name: `Sierra Health and Life` },
	{ id: 'PAN.SILVSM', name: `SilverSummit Healthplan` },
	{ id: 'PAN.SMPLHC', name: `Simply Healthcare Plans` },
	{ id: 'PAN.SCMCAID', name: `South Carolina Medicaid` },
	{ id: 'PAN.SDMCAID', name: `South Dakota Medicaid` },
	{ id: 'PAN.SIHO', name: `Southeastern Indiana Health Organization (SIHO)` },
	{ id: 'PAN.SUMMA', name: `SummaCare Health Plan` },
	{ id: 'PAN.SUNFLS', name: `Sunflower State` },
	{ id: 'PAN.SUNSHI', name: `Sunshine Health` },
	{ id: 'PAN.SUPERI', name: `Superior HealthPlan` },
	{ id: 'PAN.SUPERISH', name: `Superior Select Health (Tribute Health Plans)` },
	{ id: 'PAN.TNMCAID', name: `Tennessee Medicaid` },
	{ id: 'PAN.TXMCAID', name: `Texas Medicaid` },
	{ id: 'PAN.THP', name: `The Health Plan` },
	{
		id: 'PAN.TML',
		name: `TML Multistate Intergovernmental Employee Benefits Pool`,
	},
	{ id: 'PAN.TRICARE', name: `TRICARE For Life` },
	{ id: 'PAN.TRILLC', name: `Trillium Community Health PLan` },
	{ id: 'PAN.TRUEHNM', name: `True Health New Mexico` },
	{ id: 'PAN.TRUSTED', name: `Trusted Health Plan of DC` },
	{ id: 'PAN.TRUSTM', name: `Trustmark` },
	{ id: 'PAN.TUFTS', name: `Tufts Health Plan` },
	{ id: 'PAN.UCARE', name: `UCare` },
	{ id: 'PAN.UMR', name: `UMR` },
	{ id: 'PAN.UNICARE', name: `Unicare` },
	{ id: 'PAN.UHC', name: `UnitedHealthcare` },
	{ id: 'PAN.UHCAS', name: `UnitedHealthcare All Savers` },
	{
		id: 'PAN.UHCCPHI',
		name: `UnitedHealthcare Community Pan of Hawaii Medicare`,
	},
	{ id: 'PAN.UHCCP', name: `UnitedHealthcare Community Plan` },
	{ id: 'PAN.UHCCPAZ', name: `UnitedHealthcare Community Plan of Arizona` },
	{ id: 'PAN.UHCCPCA', name: `UnitedHealthcare Community Plan of California` },
	{ id: 'PAN.UHCCPCO', name: `UnitedHealthcare Community Plan of Colorado` },
	{ id: 'PAN.UHCCPDC', name: `UnitedHealthcare Community Plan of D.C` },
	{ id: 'PAN.UHCCPDE', name: `UnitedHealthcare Community Plan of Delaware` },
	{ id: 'PAN.UHCCPFL', name: `UnitedHealthcare Community Plan of Florida` },
	{ id: 'PAN.UHCCPGA', name: `UnitedHealthcare Community Plan of Georgia` },
	{ id: 'PAN.UHCCPIA', name: `UnitedHealthcare Community Plan of Iowa` },
	{ id: 'PAN.UHCCPKS', name: `UnitedHealthcare Community Plan of Kansas` },
	{ id: 'PAN.UHCCPKY', name: `UnitedHealthcare Community Plan of Kentucky` },
	{ id: 'PAN.UHCCPLA', name: `UnitedHealthcare Community Plan of Louisiana` },
	{ id: 'PAN.UHCCPME', name: `UnitedHealthcare Community Plan of Maine` },
	{ id: 'PAN.UHCCPMD', name: `UnitedHealthcare Community Plan of Maryland` },
	{
		id: 'PAN.UHCCPMA',
		name: `UnitedHealthcare Community Plan of Massachusetts`,
	},
	{ id: 'PAN.UHCCPMI', name: `UnitedHealthcare Community Plan of Michigan` },
	{ id: 'PAN.UHCCPMS', name: `UnitedHealthcare Community Plan of Mississippi` },
	{ id: 'PAN.UHCCPMO', name: `UnitedHealthcare Community Plan of Missouri` },
	{ id: 'PAN.UHCCPNE', name: `UnitedHealthcare Community Plan of Nebraska` },
	{ id: 'PAN.UHCCPNJ', name: `UnitedHealthcare Community Plan of New Jersey` },
	{ id: 'PAN.UHCCPNM', name: `UnitedHealthcare Community Plan of New Mexico` },
	{ id: 'PAN.UHCCPNY', name: `UnitedHealthcare Community Plan of New York` },
	{ id: 'PAN.UHCCPOH', name: `UnitedHealthcare Community Plan of Ohio` },
	{
		id: 'PAN.UHCCPPA',
		name: `UnitedHealthcare Community Plan of Pennsylvania`,
	},
	{
		id: 'PAN.UHCCPRI',
		name: `UnitedHealthcare Community Plan of Rhode Island`,
	},
	{
		id: 'PAN.UHCCPTN',
		name: `UnitedHealthcare Community Plan of Tennessee (Tenncare)`,
	},
	{ id: 'PAN.UHCCPTX', name: `UnitedHealthcare Community Plan of Texas` },
	{ id: 'PAN.UHCCPVA', name: `UnitedHealthcare Community Plan of Virginia` },
	{ id: 'PAN.UHCCPWA', name: `UnitedHealthcare Community Plan of Washington` },
	{ id: 'PAN.UHCCPWI', name: `UnitedHealthcare Community Plan of Wisconsin` },
	{ id: 'PAN.UNITY', name: `Unity Health Insurance` },
	{ id: 'PAN.UNIVERA', name: `Univera Healthcare` },
	{ id: 'PAN.UHA', name: `University Health Alliance` },
	{ id: 'PAN.UAZHP', name: `University of Arizona Health Plans` },
	{ id: 'PAN.UUTHP', name: `University of Utah Health Plans` },
	{ id: 'PAN.UPMC', name: `UPMC Health Plan` },
	{ id: 'PAN.UTMCAID', name: `Utah Medicaid` },
	{ id: 'PAN.VAPHP', name: `VA Premier Health Plan` },
	{ id: 'PAN.VCHCP', name: `Ventura County Health Care Plan (VCHCP)` },
	{ id: 'PAN.VTMCAID', name: `Vermont Medicaid` },
	{ id: 'PAN.VAMCAID', name: `Virginia Medicaid` },
	{ id: 'PAN.VIVA', name: `VIVA Health` },
	{ id: 'PAN.WAMCAID', name: `Washington Medicaid` },
	{ id: 'PAN.WEA', name: `WEA Trust (Wisconsin Educators Association)` },
	{ id: 'PAN.WELLCARE', name: `WellCare Health Plans` },
	{ id: 'PAN.WCALMCARE', name: `WellCare of Alabama - Medicare` },
	{ id: 'PAN.WCAZMCARE', name: `WellCare of Arizona - Medicare` },
	{ id: 'PAN.WCAR', name: `WellCare of Arkansas` },
	{ id: 'PAN.WCCT', name: `WellCare of Connecticut` },
	{ id: 'PAN.WCFLMCAID', name: `WellCare of FL Medicaid` },
	{ id: 'PAN.WCFLMCARE', name: `WellCare of FL Medicare` },
	{ id: 'PAN.WCGAMCAID', name: `WellCare of GA Medicaid` },
	{ id: 'PAN.WCGAMCARE', name: `WellCare of GA Medicare` },
	{ id: 'PAN.WCILMCARE', name: `WellCare of IL Medicare` },
	{ id: 'PAN.WCKY', name: `WellCare of Kentucky` },
	{ id: 'PAN.WCLA', name: `WellCare of Louisiana` },
	{ id: 'PAN.WCMS', name: `WellCare of Mississippi` },
	{ id: 'PAN.WCMO', name: `WellCare of Missouri` },
	{ id: 'PAN.WCNJMCAID', name: `WellCare of NJ Medicaid` },
	{ id: 'PAN.WCNJMCARE', name: `WellCare of NJ Medicare` },
	{ id: 'PAN.WCNYMCAID', name: `WellCare of NY Medicaid` },
	{ id: 'PAN.WCNYMCARE', name: `WellCare of NY Medicare` },
	{ id: 'PAN.WCSC', name: `WellCare of South Carolina` },
	{ id: 'PAN.WCTXMCARE', name: `WellCare of Texas Medicare` },
	{ id: 'PAN.WCTNMCARE', name: `WellCare of TN Medicare` },
	{ id: 'PAN.WELLMK', name: `Wellmark BCBS` },
	{ id: 'PAN.WVFHP', name: `West Virginia Family Health Plan` },
	{ id: 'PAN.WVMCAID', name: `West Virginia Medicaid` },
	{ id: 'PAN.WHA', name: `Western Health Advantage` },
	{ id: 'PAN.WSCC', name: `Western Sky Community Care` },
	{ id: 'PAN.WIMCAID', name: `Wisconsin Medicaid` },
	{ id: 'PAN.WPS', name: `WPS Health Insurance` },
	{ id: 'PAN.WYMCAID', name: `Wyoming Medicaid` },
	{ id: 'PAN.MOCK', name: `PAN Mock` },
];
