import { RhymeUser } from 'type-declarations';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid2 as Grid,
	TextField,
} from '@mui/material';
import { FC, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm } from 'react-hook-form';
import { getUserFormSchema } from '../validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { UserRoleAutcomplete } from './UserRoleAutocomplete';
import {
	closePartnerUsersDialog,
	CrudDialogState,
	CrudType,
	openPartnerUsersDialog,
} from 'state-services';

interface UserFormInput {
	fieldKey: 'id' | 'userName' | 'firstName' | 'lastName' | 'roles';
	label: string;
	gridXs: number;
	props?: any;
}

type Props = {
	user?: RhymeUser;
	dialogState: CrudDialogState;
};

const AddOrEditUserDialog: FC<Props> = ({ user, dialogState }) => {
	const dispatch = useAppDispatch();
	const { activeEntity } = useAppSelector((state) => state.user);
	const { name, id, approvedEmailDomains } = activeEntity;

	const isEdit = dialogState.crudType == CrudType.Update;
	const dialogTitleText = isEdit ? 'Modify User' : 'Add User';
	const buttonIcon = isEdit ? <EditIcon /> : <PersonAddIcon />;
	const isOpen = dialogState.isDialogOpen;
	const isSubmitting = dialogState.isSubmitting;

	const {
		control,
		register,
		handleSubmit,
		reset,
		trigger,
		formState: { errors, isValid },
	} = useForm<RhymeUser>({
		defaultValues: {
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
			userName: user?.userName || '',
			roles: user?.roles || [],
		},
		resolver: zodResolver(getUserFormSchema(approvedEmailDomains)),
		mode: 'onBlur',
	});

	useEffect(() => {
		//reset form defaults when user changes
		reset(user);
	}, [user]);

	const openDialog = () => {
		reset(user);
		dispatch(openPartnerUsersDialog(dialogState));
	};

	const closeDialog = () => {
		dispatch(closePartnerUsersDialog(dialogState));
	};

	const onSubmit = (data: RhymeUser) => {
		dispatch(dialogState.confirmAction({ user: data }));
	};

	const textFieldInputs: Array<UserFormInput> = [
		{ fieldKey: 'firstName', label: 'First Name', gridXs: 6 },
		{ fieldKey: 'lastName', label: 'Last Name', gridXs: 6 },
		{
			fieldKey: 'userName',
			label: 'Email Address',
			gridXs: 12,
			props: { disabled: isEdit },
		},
	];

	return (
		<>
			<Button
				size={isEdit ? 'small' : 'medium'}
				startIcon={buttonIcon}
				onClick={() => openDialog()}
				sx={{ ...(!isEdit && { pb: 1 }) }}
				disabled={dialogState.isDialogOpen}
			>
				{dialogTitleText}
			</Button>
			<Dialog open={isOpen}>
				<DialogTitle color="primary">{dialogTitleText}</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid size={{ xs: 12 }}>
								<TextField
									fullWidth
									id="org-details"
									label="Organization"
									defaultValue={`${name} (${id})`}
									disabled
								/>
							</Grid>
							{textFieldInputs.map((field, index) => {
								return (
									<Grid size={{ xs: field.gridXs }} key={index}>
										<TextField
											{...register(field.fieldKey)}
											label={field.label}
											error={!!errors[field.fieldKey]}
											helperText={errors[field.fieldKey]?.message}
											fullWidth
											{...field.props}
										/>
									</Grid>
								);
							})}
							<Grid size={{ xs: 12 }}>
								<UserRoleAutcomplete
									control={control}
									activeEntity={activeEntity}
									errors={errors}
									trigger={trigger}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							type="submit"
							startIcon={<CheckCircleIcon />}
							disabled={isSubmitting || !isValid}
						>
							{isSubmitting ? 'Saving...' : 'Save'}
						</Button>
						<Button
							onClick={() => closeDialog()}
							startIcon={<CancelIcon />}
							disabled={isSubmitting}
						>
							Cancel
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

export default AddOrEditUserDialog;
