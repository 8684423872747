/* eslint-disable no-prototype-builtins */
import {
	IntegrationDisplayNames,
	SurveySubmitContent,
	Submission,
	BACKEND_ROUTES,
} from 'type-declarations';
import { UserState } from '../user';

// ==================== All Surveys
export const buildSubmitContentArray = ({
	caseId,
	submissions,
	user,
}: {
	caseId: string;
	submissions: Submission[];
	user: UserState;
}) => {
	const newSubmissionIds = submissions
		.filter((s) => s.submissionStatus!.status === 'SURVEY_REQUIRED')
		.map((fs) => fs.submissionId!);

	return newSubmissionIds.reduce(
		(submitContentArray: SurveySubmitContent[], submissionId: string) => {
			const matchedSubmission = submissions.find(
				(submission) => submission.submissionId === submissionId
			)!;
			const umIntegrationUrl = BACKEND_ROUTES.RhymeApi;
			const submitContent: SurveySubmitContent = {
				request: {
					id: submissionId,
					userFirstName: user.firstName,
					userLastName: user.lastName,
					userId: user.id,
					userEmail: user.userName,
				},
				url: `${umIntegrationUrl}organization/${user.activeEntity.id}/case/${caseId}/submission/${submissionId}
					/payer-integration/${matchedSubmission.routeId.toLowerCase()}/survey`,
				umIntegrationUrl: umIntegrationUrl,
				integrationName: toUserFriendlyIntegrationName(matchedSubmission.routeId!) || '',
			};

			submitContentArray.push(submitContent);
			return submitContentArray;
		},
		[]
	);
};

export const toUserFriendlyIntegrationName = (benefitManagerName: string) => {
	if (!benefitManagerName) return;
	const foundDisplayName =
		IntegrationDisplayNames[
			benefitManagerName.toUpperCase() as keyof typeof IntegrationDisplayNames
		];
	return foundDisplayName ? foundDisplayName : benefitManagerName;
};

// ==================== Olive MFE Specific
export const clearOliveSessionStorageToken = () => {
	sessionStorage.removeItem('token');
	sessionStorage.removeItem('apiEnv');
	sessionStorage.removeItem('OliveMFESurveyPayload');
};

export const setOliveAuthDataToSessionStorage = (response: any) => {
	// Used only survey type "OliveMfe". See BaseIframe.tsx & getIframeSource()
	// OliveMFESurveyPayload is used by the oliveMFESurvey.html/js assets
	if (response.data.payload != undefined && response.data.transportFormat === 'OliveMFE') {
		const oliveMFESurveyPayload = JSON.parse(response.data.payload);
		sessionStorage.setItem('OliveMFESurveyPayload', response.data.payload);
		sessionStorage.setItem('token', oliveMFESurveyPayload.AuthDetailsResponse?.tokenResponse?.token ?? '');
		sessionStorage.setItem('apiEnv', oliveMFESurveyPayload.AuthDetailsResponse?.apiEnv ?? '');
	}
};
