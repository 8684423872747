import { fetchAuthSession, LibraryOptions } from '@aws-amplify/core';
import { type ResourcesConfig } from 'aws-amplify';
import { fetchMFAPreference } from 'aws-amplify/auth';
import { BACKEND_ROUTES } from 'type-declarations';
import { RHYME_API_ENDPOINTS } from './apiEndpoints';

export const awsConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			userPoolId: process.env.REACT_APP_STANDARD_USER_POOL_ID ?? '',
			userPoolClientId: process.env.REACT_APP_STANDARD_CLIENT_ID ?? '',
		},
	},
	API: {
		REST: RHYME_API_ENDPOINTS(BACKEND_ROUTES),
	},
};

export const authRules: LibraryOptions = {
	API: {
		REST: {
			headers: async () => {
				const rsoLocalStorageToken = localStorage.getItem('idToken');
				const idToken = rsoLocalStorageToken ?? (await handleFetchAuthSession());
				return { Authorization: `${idToken}` };
			},
		},
	},
};

export const handleFetchAuthSession = async () => {
	try {
		const idToken = (await fetchAuthSession({ forceRefresh: true })).tokens?.idToken?.toString();
		return idToken;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(error);
	}
};

export const handleFetchMfaPreference = async () => {
	try {
		const preferredMfa = (await fetchMFAPreference()).preferred?.toString();
		return preferredMfa;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(error);
	}
};
