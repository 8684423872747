import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, put } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import {
	FacilityRule,
	FacilityRuleConflictResponse,
	FacilityRuleRequest,
	RenderingFacility,
	RHYME_API_SERVICES,
} from 'type-declarations';
import { restFacilities } from './slice';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchProviderFacilities = createAsyncThunk(
	'provider-facility/fetchProviderFacilities',
	async (_, { getState, dispatch }) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility`,
			}).response;
			const data = (await body.json()) as unknown as RenderingFacility[];
			const facilities = data.filter((f) => !f.isDeleted);
			facilities.forEach(
				(f) => (f.formattedAddress = [f.address1, f.city, f.state, f.zip].join(' '))
			);
			return facilities;
		} catch (error) {
			dispatch(restFacilities());
			throw new Error(`Fetch provider facilities failed with error: ${error}`);
		}
	}
);

export const createProviderFacility = createAsyncThunk(
	'provider-facility/createProviderFacility',
	async (
		{
			facility,
			createRule,
			ruleRequest,
		}: { facility: RenderingFacility; createRule: boolean; ruleRequest: FacilityRuleRequest },
		{ getState, dispatch }
	) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;
		const params = {
			body: {
				...facility,
			},
		};

		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility`,
				options: params,
			}).response;
			const data = (await body.json()) as unknown as RenderingFacility;

			SnackbarManager.show({
				message: 'Created new facility.',
				type: 'success',
			});

			if (createRule) {
				const facilityRule: FacilityRuleRequest = {
					...ruleRequest,
					facilityId: data.panProviderId,
				};
				dispatch(createProviderFacilityRule({ ruleRequest: facilityRule }));
			}
			dispatch(fetchProviderFacilities());
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to created new facility.',
				type: 'error',
			});
			throw new Error(`Create provider facility failed with error: ${error}`);
		}
	}
);

export const editProviderFacility = createAsyncThunk(
	'provider-facility/editProviderFacility',
	async ({ facility }: { facility: RenderingFacility }, { getState, dispatch }) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;
		const params = {
			body: { ...facility },
		};
		try {
			await put({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility`,
				options: params,
			}).response;
			dispatch(fetchProviderFacilities());
			if (facility.isDeleted) {
				SnackbarManager.show({
					message: 'Deleted facility.',
					type: 'success',
				});
			} else {
				SnackbarManager.show({
					message: 'Updated facility.',
					type: 'success',
				});
			}
		} catch (error) {
			SnackbarManager.show({
				message: 'Updating facility failed.',
				type: 'error',
			});

			throw new Error(`Edit provider facility failed with error: ${error}`);
		}
	}
);

export const editProviderFacilityRule = createAsyncThunk(
	'provider-facility/editProviderFacilityRule',
	async ({ ruleRequest }: { ruleRequest: FacilityRuleRequest }, { getState, dispatch }) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;
		const params = {
			body: { ...ruleRequest },
		};
		try {
			await put({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility-rule`,
				options: params,
			}).response;
			dispatch(fetchProviderFacilities());
			SnackbarManager.show({
				message: 'Updated facility.',
				type: 'success',
			});
		} catch (error) {
			SnackbarManager.show({
				message: 'Updating facility failed.',
				type: 'error',
			});
			throw new Error(`Edit provider facility rule failed with error: ${error}`);
		}
	}
);

export const createProviderFacilityRule = createAsyncThunk(
	'provider-facility/createProviderFacilityRule',
	async ({ ruleRequest }: { ruleRequest: FacilityRuleRequest }, { getState }) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;
		const params = {
			body: { ...ruleRequest },
		};
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility-rule`,
				options: params,
			});
			SnackbarManager.show({
				message: 'Created facility rules.',
				type: 'success',
			});
		} catch (error) {
			SnackbarManager.show({
				message: 'Creating facility rules failed.',
				type: 'error',
			});
			throw new Error(`Create provider facility rule failed with error: ${error}`);
		}
	}
);

export const fetchProviderFacilityRule = createAsyncThunk(
	'provider-facility/fetchProviderFacilityRule',
	async ({ panProviderId }: { panProviderId: string }, { getState }) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;

		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility-rule/${panProviderId}`,
			}).response;
			const data = (await body.json()) as unknown as FacilityRule;
			return data;
		} catch (error) {
			throw new Error(`Create provider facility rule faield with error: ${error}`);
		}
	}
);

export const validateProviderFacilityRule = createAsyncThunk(
	'provider-facility/validateProviderFacilityRule',
	async (
		{
			ruleRequest,
		}: {
			ruleRequest: FacilityRuleRequest;
			rule: FacilityRule;
		},
		{ getState }
	) => {
		const appState: any = getState();
		const activeEntityId = appState.user.activeEntity.id;
		const params = {
			body: { ...ruleRequest },
		};
		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/configuration/facility-rule/get-conflict`,
				options: params,
			}).response;
			const data = (await body.json()) as unknown as FacilityRuleConflictResponse;
			const duplicate = data.isDuplicate === true;
			const conflict = data.isConflict === true;
			if (duplicate || conflict) {
				if (duplicate && conflict)
					SnackbarManager.show({
						message: 'Rule is duplicate & in conflict.',
						type: 'error',
					});
				else if (duplicate)
					SnackbarManager.show({
						message: 'Rule is duplicate.',
						type: 'error',
					});
				else if (conflict)
					SnackbarManager.show({
						message: 'Rule is in conflict.',
						type: 'error',
					});
			}
			const hasConflict: boolean = duplicate || conflict;
			return hasConflict;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			return false;
		}
	}
);
