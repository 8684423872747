import { getAppEnv } from 'env-utilities';

const appEnv = getAppEnv();

export const EXTERNAL_ROUTES = {
	ReactTachyon:
		appEnv === 'prod'
			? 'https://portal.getrhyme.com/live-auth'
			: `https://portal.${appEnv}.getrhyme.com/live-auth`,
	ReactPortal:
		appEnv === 'prod' ? 'https://portal.getrhyme.com/' : `https://portal.${appEnv}.getrhyme.com/`,
};
