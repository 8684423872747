import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSurvey } from './thunks';
import { Survey, SliceStatus } from 'type-declarations';

export interface SurveyState {
	status: SliceStatus;
	surveys: Survey[];
}

export const initialSurveyState: SurveyState = {
	status: SliceStatus.IDLE,
	surveys: [],
};

export const surveysSlice = createSlice({
	name: 'survey',
	initialState: initialSurveyState,
	reducers: {
		resetSurveysSlice: (state) => {
			Object.assign(state, initialSurveyState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchSurvey.pending, (state) => {
			Object.assign(state, initialSurveyState);
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(fetchSurvey.fulfilled, (state, action: PayloadAction<Survey[] | null>) => {
			if (action.payload !== null) {
				state.status = SliceStatus.SUCCEEDED;
				state.surveys = action.payload;
			} else {
				state.status = SliceStatus.REJECTED;
			}
		});
		builder.addCase(fetchSurvey.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
	},
});

export const surveysReducer = surveysSlice.reducer;
export const surveysActions = surveysSlice.actions;
export const { resetSurveysSlice } = surveysActions;
