export enum errorCodes {
	MISSING_DATA = 'MISSING_DATA',
	TOO_LONG = 'TOO_LONG',
	FORMAT_ERROR = 'FORMAT_ERROR',
	NOT_FOUND = 'NOT_FOUND',
	ERROR = 'ERROR',
}

export interface StandardResponse {
	valid: boolean;
	timestamp: string;
	errors: ValidationError[];
}

export interface CorrectionsNeededResponse {
	timestamp: string;
	partnerCaseId: string;
	organizatonId: string;
	popupUrl: string;
	panDataGatheringUrl: string;
	services: unknown[];
	errors: ValidationError[];
}

export interface ValidationError {
	errorCode: errorCodes;
	relatedTo: string;
	message: string;
	values: string[] | undefined;
}

/**
 * Safely inspects an unknown object and returns the first
 * error message as expected on a StandardResponse, if possible.
 * Useful for passing an error to a Snackbar or an Alert.
 * @param data an object that may be a StandardResponse
 * @returns The first message in the errors array or undefined, if not found.
 */
export const tryGetFirstStandardResponseError = (data: unknown): string | undefined => {
	if (isStandardResponseWithErrors(data)) {
		return (data as StandardResponse)?.errors?.at(0)?.message ?? undefined;
	}
	return undefined;
};

/**
 * Safely determines if an unknown object is a StandardResponse with at least one
 * error.
 * @param data an object that may be a StandardResponse
 */
export const isStandardResponseWithErrors = (data: unknown): boolean => {
	if (!data) {
		return false;
	}
	const standardResponse = data as StandardResponse;
	return standardResponse.errors && standardResponse.errors.length > 0;
};

/**
 * Safely determines if an optional JSON string is a StandardResponse
 * with at least one error.
 * @param data JSON string content that can be undefined
 */
export const isJsonStringStandardResponseWithErrors = (json: string | undefined): boolean => {
	if (!json) {
		return false;
	}
	return isStandardResponseWithErrors(JSON.parse(json));
};

export const forSection = (valError: ValidationError, section: string | Array<string>): boolean => {
	const toCheck = typeof section === 'string' ? [section] : section;
	return toCheck.some((toCheckItem) =>
		valError.relatedTo.toLowerCase().includes(toCheckItem.toLowerCase())
	);
};

export const forExactSection = (
	valError: ValidationError,
	section: string | Array<string>
): boolean => {
	const toCheck = typeof section === 'string' ? [section] : section;
	return toCheck.some(
		(toCheckItem) => valError.relatedTo.toLowerCase() === toCheckItem.toLowerCase()
	);
};
