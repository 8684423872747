import { createAsyncThunk } from '@reduxjs/toolkit';
import { DiagnosisDetails, ProcedureDetails, RHYME_API_SERVICES } from 'type-declarations';
import { SnackbarManager } from 'rhyme-component-library';
import { ApiError, get } from 'aws-amplify/api';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200];

export const fetchPrimaryDiagnosis = createAsyncThunk(
	'referral/fetchPrimaryDiagnosis',
	async ({ referralDiagnosesCode }: { referralDiagnosesCode: string }) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `ontology/diagnosis/${referralDiagnosesCode}`,
			}).response;
			const data = (await body.json()) as unknown as DiagnosisDetails;
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					SnackbarManager.show({ message: 'Failed to locate the diagnosis code', type: 'error' });
					throw new Error(
						`Response status of ${statusCode}, 
        MDM service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			SnackbarManager.show({ message: 'Failed to locate the diagnosis code', type: 'error' });
			throw new Error(`Fetch diagnosis failed with error: ${error}`);
		}
	}
);

export const fetchPrimaryProcedure = createAsyncThunk(
	'referral/fetchPrimaryProcedure',
	async ({ primaryProcedureCode }: { primaryProcedureCode: string }) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `ontology/procedure/${primaryProcedureCode}`,
			}).response;
			const data = (await body.json()) as unknown as ProcedureDetails;
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					SnackbarManager.show({ message: 'Failed to locate the procedure code', type: 'error' });
					throw new Error(
						`Response status of ${statusCode}, 
        MDM service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			SnackbarManager.show({ message: 'Failed to locate the procedure code', type: 'error' });
			throw new Error(`Fetch procedure failed with error: ${error}`);
		}
	}
);
