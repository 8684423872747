import { RoleTypes } from 'type-declarations';
import { UserCapabilities } from './constants';

export type PortalPermissions =
	| 'PRIOR_AUTH_CASE_MANAGER'
	| 'RHYME_SUPPORT'
	| 'RHYME_DOCUMENT_LIBRARY'
	| 'PROVIDER_ADMIN_SETTINGS'
	| 'RHYME_ANALYTICS_REPORTING'
	| 'SUPPORT_EMAILS';

type Permissions = { [key in RoleTypes]: PortalPermissions[] };

export const permissions: Permissions = {
	organizationAdmin: [
		'PRIOR_AUTH_CASE_MANAGER',
		'RHYME_SUPPORT',
		'RHYME_ANALYTICS_REPORTING',
		'PROVIDER_ADMIN_SETTINGS',
		'SUPPORT_EMAILS',
		'RHYME_DOCUMENT_LIBRARY',
	],
	paRequester: [
		'PRIOR_AUTH_CASE_MANAGER',
		'RHYME_SUPPORT',
		'RHYME_DOCUMENT_LIBRARY',
		'RHYME_ANALYTICS_REPORTING',
	],
	disabled: [],
	customerUpdatesOnly: [],
	ProviderAdmin: [
		'PRIOR_AUTH_CASE_MANAGER',
		'RHYME_SUPPORT',
		'RHYME_ANALYTICS_REPORTING',
		'PROVIDER_ADMIN_SETTINGS',
		'SUPPORT_EMAILS',
		'RHYME_DOCUMENT_LIBRARY',
	],
	ProviderReportViewer: ['RHYME_ANALYTICS_REPORTING'],
};

export const hasRolePermission = (
	roles: RoleTypes[],
	expectedPermission: PortalPermissions
): boolean => {
	const isIncluded = roles.find((r) => permissions[r].includes(expectedPermission));
	return isIncluded ? true : false;
};

export const hasCapabilityPermission = (
	capabilities: string[] | undefined,
	expectedCapabilities: UserCapabilities[]
): boolean => {
	if (capabilities === undefined) {
		return false;
	}

	return expectedCapabilities.every((expectedCap) => capabilities.includes(expectedCap));
};
