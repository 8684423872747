// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@assets/GEO_Pattern.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customMessage{min-height:100vh;background:linear-gradient(rgba(214, 237, 216, 0.7), rgba(214, 237, 216, 0.9)),url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-size:cover}`, "",{"version":3,"sources":["webpack://./../../shared/components/rhyme-component-library/src/components/CustomMessage/customMessage.css"],"names":[],"mappings":"AAAA,eACE,gBAAA,CACA,sHAAA,CAEA,2BAAA,CACA,qBAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
