/* eslint-disable no-console */
import React from 'react';
import { FC } from 'react';
import { signOut as SignOut } from 'aws-amplify/auth';
import { Backdrop, Box, Modal, Typography } from '@mui/material';

interface MyProps {
	showModal: boolean;
}

export const SessionTimerNotification: FC<MyProps> = ({ showModal }) => {
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<>
			{showModal ? (
				<div>
					<Modal
						aria-labelledby="transition-modal-title"
						aria-describedby="transition-modal-description"
						open={showModal}
						closeAfterTransition
						slots={{ backdrop: Backdrop }}
						slotProps={{
							backdrop: {
								timeout: 500,
							},
						}}
					>
						<Box sx={style}>
							<Typography id="transition-modal-title" variant="h6" component="h2">
								Session Expiration Notification.
							</Typography>
							<Typography id="transition-modal-description" sx={{ mt: 2 }}>
								Your session will expire soon due to inactivity.
							</Typography>
						</Box>
					</Modal>
				</div>
			) : null}
		</>
	);
};

export class SessionTimer {
	private static instance: SessionTimer | null = null;
	private sessionLength: number;
	private sessionExpireWarning: number;
	private sessionTimerCounter: number;
	private showModal: boolean;
	private currentTimerObject: unknown | null;
	private listeners: ((showModal: boolean) => void)[] = [];
	public static showModal: boolean;

	private constructor() {
		this.sessionLength = 15; // 15 minutes
		this.sessionExpireWarning = 1; // 1 minute
		this.sessionTimerCounter = 1000 * 60 * this.sessionLength;
		this.showModal = false;
		this.currentTimerObject = null;
		this.listeners = [];
	}

	public static startSessionTimer() {
		try {
			if (!SessionTimer.instance) {
				SessionTimer.instance = new SessionTimer();

				if (!SessionTimer.instance.currentTimerObject) {
					SessionTimer.instance.currentTimerObject = setInterval(() => {
						if (SessionTimer.instance) SessionTimer.instance.sessionTimerCounter! -= 1000;
						if (
							SessionTimer.instance?.sessionTimerCounter! <=
								1000 * 60 * this.instance!.sessionExpireWarning &&
							SessionTimer.instance?.sessionTimerCounter! > 0
						) {
							SessionTimer.instance?.setShowModal(true);
						} else if (SessionTimer.instance?.sessionTimerCounter! <= 0) {
							this.stopSessionTimer();
						}
					}, 1000);
				}
			}
		} catch (err) {
			console.warn(err);
		}
	}
	public static resetSessionTimer() {
		if (this.instance) {
			this.instance.sessionTimerCounter = 1000 * 60 * this.instance!.sessionLength;
			this.instance.setShowModal(false);
		}
	}
	public static stopSessionTimer() {
		if (this.instance) {
			this.instance.setShowModal(false);
			clearInterval(this.instance.currentTimerObject as number);
			SignOut();
			window.location.href = '/';
		}
	}

	// Listeners to hook into the SingletonService for React Components
	public setShowModal(showModal: boolean) {
		this.showModal = showModal;
		SessionTimer.instance ? SessionTimer.instance.emitShowModalChange(showModal) : false;
	}
	public static getShowModal() {
		return this.instance ? this.instance.showModal : false;
	}
	public static addShowModalChangeListener(listener: (showModal: boolean) => void) {
		if (this.instance) this.instance.listeners.push(listener);
	}
	public static removeShowModalChangeListener(listener: (showModal: boolean) => void) {
		if (this.instance?.listeners)
			this.instance.listeners = this.instance?.listeners.filter((l) => l !== listener);
	}
	private emitShowModalChange(showModal: boolean) {
		if (this.listeners) this.listeners.forEach((listener) => listener(showModal));
	}
}
