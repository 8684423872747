import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// UI state for managing global properties
export interface UiState {
	isPolling: boolean;
}

export const initialUiState: UiState = {
	isPolling: false,
};

export const uiSlice = createSlice({
	name: 'ui',
	initialState: initialUiState,
	reducers: {
		setIsPolling: (state, action: PayloadAction<boolean>) => {
			state.isPolling = action.payload;
		},
	},
});

export const uiReducer = uiSlice.reducer;
export const uiActions = uiSlice.actions;
export const { setIsPolling } = uiActions;
