import { CaseStatus, ColorClass } from './enums';
import { CaseStatusDisplayMapping } from '../../services/case/models';

export const CaseStatusDisplayMappings: Array<CaseStatusDisplayMapping> = [
	{
		statusId: CaseStatus.APPROVED,
		displayStatus: 'Approved',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.APPROVED_WITH_MODIFICATIONS,
		displayStatus: 'Approved with Modifications',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.CANCELED,
		displayStatus: 'Canceled',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.CLINICAL_NEEDED,
		displayStatus: 'Clinical Needed',
		colorClass: ColorClass.warning,
	},
	{
		statusId: CaseStatus.CLINICAL_REQUESTED_PROVIDER_NOTIFIED,
		displayStatus: 'Clinical Requested - Provider Notified',
		colorClass: ColorClass.warning,
	},
	{
		statusId: CaseStatus.DENIED,
		displayStatus: 'Denied',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.IN_REVIEW,
		displayStatus: 'In Review',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.INCOMPLETE,
		displayStatus: 'Incomplete',
		colorClass: ColorClass.warning,
	},
	{
		statusId: CaseStatus.MEMBER_NOT_COVERED,
		displayStatus: 'Member Not Covered',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.MEMBER_NOT_SUPPORTED,
		displayStatus: 'Member Not Supported',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.MULTIPLE_DECISIONS,
		displayStatus: 'Multiple Decisions',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.NOT_COVERED,
		displayStatus: 'Not Covered',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.NOT_ELIGIBLE,
		displayStatus: 'Not Eligible',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.NOT_REQUIRED,
		displayStatus: 'Not Required',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.PARTIALLY_APPROVED,
		displayStatus: 'Partially Approved',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.PARTIALLY_REVIEWED,
		displayStatus: 'Partially Reviewed',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.PEER_TO_PEER,
		displayStatus: 'Peer to Peer',
		colorClass: ColorClass.warning,
	},
	{
		statusId: CaseStatus.PROCESSING,
		displayStatus: 'Processing',
		colorClass: ColorClass.primary,
	},
	{
		statusId: CaseStatus.SERVICE_NOT_HANDLED,
		displayStatus: 'Service Not Handled',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.SURVEY_REQUIRED,
		displayStatus: 'Survey Required',
		colorClass: ColorClass.warning,
	},
	{
		statusId: CaseStatus.UNABLE_TO_PROCEED,
		displayStatus: 'Unable to Proceed',
		colorClass: ColorClass.error,
	},
	{
		statusId: CaseStatus.WITHDRAWN,
		displayStatus: 'Withdrawn',
		colorClass: ColorClass.error,
	},
];
