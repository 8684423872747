/* eslint-disable max-len */
/* eslint-disable no-prototype-builtins */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	FHIRQuestionnaireResponse,
	RHYME_API_SERVICES,
	SdgFhirClinicalAttestationResponse,
} from 'type-declarations';
import { post } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

//* OK
export const fetchSurveyContinuation = createAsyncThunk(
	'survey/fetchSurveyContinuation',
	async ({
		continuationUrl,
		requestBody,
	}: {
		continuationUrl: string;
		requestBody: FHIRQuestionnaireResponse;
	}) => {
		try {
			const { body, statusCode } = await post({
				apiName: rhymeApiUrl,
				path: continuationUrl.split('.com/')[1],
				options: { body: requestBody as any },
			}).response;
			let data: any = await body.text();
			if (data.length == 0) {
				return {
					resultError: statusCode !== 200 ? true : false,
				} as SdgFhirClinicalAttestationResponse;
			} else {
				data = JSON.parse(data);
				data.resultError = statusCode !== 200 ? true : false;
				return data as unknown as SdgFhirClinicalAttestationResponse;
			}
		} catch (error) {
			SnackbarManager.show({
				message: 'Survey continuation request failed.',
				type: 'error',
			});
			return {
				resultError: true,
			} as SdgFhirClinicalAttestationResponse;
		}
	}
);
