import { FC } from 'react';
import {
	DataGrid,
	GridColDef,
	GridRowSelectionModel,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Grid2 as Grid } from '@mui/material';
import UserDetails from './UserDetails';
import { RhymeUser } from 'type-declarations';
import AddOrEditUserDialog from './AddOrEditUserDialog';
import {
	PartnerUsersState,
	selectAddDialogState,
	selectDeleteDialogState,
	selectEditDialogState,
	selectRow,
} from 'state-services';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';

type Props = {
	columns: GridColDef[];
	users: RhymeUser[];
	loading: boolean;
};

const Users: FC<Props> = ({ columns, users, loading }) => {
	const dispatch = useAppDispatch();

	const partnerUsersState = useAppSelector<PartnerUsersState>((state) => state.admin.partner.users);
	const { selectedRow } = partnerUsersState;

	let selectedUserId = '';
	if (selectedRow && selectedRow[0]) {
		selectedUserId = selectedRow[0].toString();
	}
	const userSelected = selectedUserId.length > 0;

	const handleSelectedUser = (selectedRows: GridRowSelectionModel) => {
		dispatch(selectRow(selectedRows));
	};

	const customToolbar = () => {
		return (
			<GridToolbarContainer sx={{ margin: 1 }}>
				<GridToolbarQuickFilter disabled={loading} sx={{ width: '100%' }} variant="outlined" />
			</GridToolbarContainer>
		);
	};

	return (
		<>
			<AddOrEditUserDialog dialogState={selectAddDialogState(partnerUsersState)} />
			<Grid container sx={{ mt: 1 }}>
				{/* users table */}
				<Grid size={userSelected ? 7 : 12}>
					<DataGrid
						disableMultipleRowSelection
						autoHeight
						rows={users}
						columns={columns}
						loading={loading && users.length === 0}
						initialState={{
							pagination: {
								paginationModel: { page: 0, pageSize: 10 },
							},
							sorting: {
								sortModel: [{ field: 'fullName', sort: 'asc' }],
							},
						}}
						pageSizeOptions={[10, 15]}
						rowSelectionModel={selectedRow}
						onRowSelectionModelChange={(selectedRows) => handleSelectedUser(selectedRows)}
						sx={{ marginRight: 2 }}
						slots={{ toolbar: customToolbar }}
					/>
				</Grid>
				{/* user detail card */}
				{userSelected && (
					<Grid size={5}>
						<UserDetails
							userId={selectedUserId}
							users={users}
							editDialogState={selectEditDialogState(partnerUsersState)}
							deleteDialogState={selectDeleteDialogState(partnerUsersState)}
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default Users;
