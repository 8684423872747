import { getAppEnv } from 'env-utilities';

//Add a record to BackendRoutes using the key from RhymeServices and assign a string for the service's URL.
export enum RHYME_API_SERVICES {
	RhymeApi = 'RhymeApi',
	OrganizationService = 'OrganizationService',
	LocalHost = 'LocalHost',
}

const appEnv = getAppEnv();

export type ServiceEnumRoute = Record<RHYME_API_SERVICES, string>;

/**
 * Note when adding a new backEndRoute
 * add the endpoint to EI to prevent CSP blocking
 * folder TachyonReactUI -> locals.ts to safe_api_urls[]
 */
export const BACKEND_ROUTES: ServiceEnumRoute = {
	RhymeApi: `https://api.${appEnv}.getrhyme.com/`,
	OrganizationService: `https://organization.${appEnv}.priorauthnow.com/jwt/`,
	LocalHost: 'https://localhost:5001/',
};
