import { createAsyncThunk } from '@reduxjs/toolkit';
import { CaseModel } from 'type-declarations';
import { UserState } from '../user';
import Axios from 'axios';
import { buildSubmitContentArray, setOliveAuthDataToSessionStorage } from './utils';

export const fetchSurvey = createAsyncThunk('survey/fetchSurvey', async (_, { getState }) => {
	const appState: any = getState();
	const { case: caseSlice }: { case: CaseModel } = appState.case;
	const { user: userSlice }: { user: UserState } = appState;

	const surveyRequests = buildSubmitContentArray({
		caseId: caseSlice.caseId,
		submissions: caseSlice.submissions,
		user: userSlice,
	});

	try {
		// TODO: API calls & post response will need expanded on as new survey scenarios are introduced
		const apiCalls = await Promise.all(
			surveyRequests.map(async (survey) => {
				const response = await Axios.get(survey.url, {
					headers: {
						Authorization: userSlice.idToken,
					},
				});

				// Used only survey type "OliveM", captures undefined is OK.
				setOliveAuthDataToSessionStorage(response);

				// TODO: Remove this once Vue Tachyon is replaced & Mock UM has been updated to point here
				if (response?.data?.actionEndpoint.includes('priorauthnow.com/mockSurvey.html')) {
					response.data.actionEndpoint = `
						${window.location.origin}
						/live-auth/assets/surveys/mockSurvey.html?
						${response.data.actionEndpoint.split('?')[1]}`;
				}

				return {
					response: response?.data,
					umIntegrationUrl: survey.umIntegrationUrl,
					integrationName: survey.integrationName,
				};
			})
		);
		// Return filter response objs with only response property
		return apiCalls.filter((x) => !!x.response) ?? null;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(error);
	}
});

export const fetchOliveManifest = createAsyncThunk('survey/fetchOliveManifest', async () => {
	const oliveMFESurveyPayloadStr = sessionStorage.getItem('OliveMFESurveyPayload');
	let oliveMFESurveyPayload;

	if (oliveMFESurveyPayloadStr) {
		oliveMFESurveyPayload = JSON.parse(oliveMFESurveyPayloadStr);
		const hostUrl = oliveMFESurveyPayload.ManifestJsonUrl;
		const res = await Axios.get(`${hostUrl}/asset-manifest.json`);

		return res;
	}
	return null;
});

export const submitSurvey = createAsyncThunk('survey/submitSurvey', async (_, { getState }) => {
	const appState: any = getState();
	const { user: userSlice }: { user: UserState } = appState;
	const survey = appState.surveys.surveys[0] ?? null;

	if (survey) {
		const config = {
			headers: {
				Authorization: userSlice.idToken,
			},
		};
		const callbackUrl = survey.response.callbackUrl.startsWith('/')
			? survey.response?.callbackUrl.substr(1)
			: survey.response?.callbackUrl;

		// Used to notify Rhyme/UI of survey completion
		const res = await Axios.post(`${survey.umIntegrationUrl}${callbackUrl}`, {}, config);

		return res && res.data ? res.data : null;
	}
});
