import { getEntityTypeFromScopeKey } from 'user-utilities';
import { Entity, EntityType, JWTBody, ScopeKeyOptions, User, UserRole } from 'type-declarations';

type ActiveEntitiesDetails = {
	activePartnerEntityId: string;
	partnerEntityType: string;
};

export const decodeJwtBody = (jwt: string): JWTBody => {
	if (!jwt) return {} as JWTBody;
	try {
		const sections = jwt.split('.');
		const parse = JSON.parse(window.atob(sections[1])) as JWTBody;

		return parse;
	} catch {
		return {} as JWTBody;
	}
};

export const combineAndParseEntities = (user: User): Entity[] => {
	// TODO: Global Admins with no scope are removed here. Revist this later
	user.userRoles = user.userRoles.filter((e: any) => Object.keys(e.scope).length !== 0);

	const result = user.userRoles.reduce((acc: Entity[], curr: UserRole) => {
		const scopeKey = Object.keys(curr.scope);
		const scopeValue = Object.values(curr.scope);
		const alreadyExist = Object.values(acc).find((e) => e.entityId === scopeValue[0]);

		if (alreadyExist) {
			alreadyExist.roles.push(curr.name);
			const set = new Set([...alreadyExist.capabilities, ...curr.capabilities]);
			alreadyExist.capabilities = Array.from(set);
		} else {
			acc.push({
				entityId: scopeValue[0] as string,
				roles: [curr.name],
				scopeKey: scopeKey[0],
				capabilities: curr.capabilities,
			});
		}
		return acc;
	}, []);
	return result;
};

export const parseEntities = (auth: string) => {
	// TODO: Global Admins with no scope are removed here. Revist this later
	const value = JSON.parse(auth.toString()).userRoles.filter(
		(e: any) => Object.keys(e.scope).length !== 0
	);
	return value;
};

/**
 * If the current URL path contains information about what Partner Entity the user is viewing something as,
 * use that Partner Entity as the active entity.
 * Currently only relevant for Provider Organizations in react-tachyon.
 */
export const getActiveEntityFromPath = (): ActiveEntitiesDetails | null => {
	const pathParts = window.location.pathname.split('/');
	const indexOfOrganization = pathParts.indexOf('organization');
	if (indexOfOrganization > -1) {
		//pattern is organization/1234567890 -- so the orgId will immediately
		//follow the string "organization" in the split array
		const orgIdFromRoute = pathParts[indexOfOrganization + 1];
		if (orgIdFromRoute.length == 10) {
			return {
				activePartnerEntityId: orgIdFromRoute,
				partnerEntityType: EntityType.PROVIDER_ORGANIZATION,
			};
		}
	}
	return null;
};

/**
 * @return activePartnerEntityId: string (first payer institution || first org with role type of ProviderAdmin || first org entityId)
 * @return partnerEntityType: boolean (true if activeOrg is payer institution)
 */
export const getActiveEntityDetails = (
	entities: Entity[],
	removePayerEntities?: boolean
): ActiveEntitiesDetails => {
	const activeEntityFromPath = getActiveEntityFromPath();
	if (activeEntityFromPath) {
		return activeEntityFromPath;
	}

	const payerInstitutions = removePayerEntities
		? []
		: entities.filter((e) => e.scopeKey === ScopeKeyOptions.PAYER_INSTITUTION_ID);
	const providerAdminOrg = entities.find((e) => e.scopeKey === ScopeKeyOptions.ORGANIZATION_ID);
	const delegatedBenefitManagers = entities.filter(
		(e) => e.scopeKey === ScopeKeyOptions.DELEGATED_BENEFIT_MANAGER_ID
	);

	// choose a payer first, if none then org, then dbm, then fall back to random first entity
	const { orgId, entityType } =
		payerInstitutions.length > 0
			? {
					orgId: payerInstitutions[0].entityId,
					entityType: EntityType.PAYER_INSTITUTION,
				}
			: providerAdminOrg
				? {
						orgId: providerAdminOrg.entityId,
						entityType: EntityType.PROVIDER_ORGANIZATION,
					}
				: delegatedBenefitManagers
					? {
							orgId: delegatedBenefitManagers[0].entityId,
							entityType: EntityType.DELEGATED_BENEFIT_MANAGER,
						}
					: {
							orgId: entities[0].entityId,
							entityType: getEntityTypeFromScopeKey(entities[0].scopeKey),
						};

	return { activePartnerEntityId: orgId, partnerEntityType: entityType };
};
