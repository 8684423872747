import { FileUploadState } from './slice';
import { DocumentType, LocalErrorFileDataStatus } from 'type-declarations';

//Selectors for FileUploadState
//Selectors concepts explained here: https://redux.js.org/usage/deriving-data-selectors#calculating-derived-data-with-selectors

/**
 * Returns the full clinical note ExtendedFileData object.
 */
export const selectClinicalNoteObject = (state: FileUploadState) =>
	state.clinicalInformation.find((c) => c.documentType == DocumentType.ClinicalNote);

/**
 * Returns the user-provided clinical note text currently stored in state.
 */
export const selectClinicalNoteText = (state: FileUploadState) =>
	selectClinicalNoteObject(state)?.clinicalNoteText ?? '';

/**
 * Returns the clinical documents the user has selected from their file system currently
 * stored in state.
 */
export const selectClinicalDocuments = (state: FileUploadState) =>
	state.clinicalInformation.filter((c) => c.documentType == DocumentType.ClinicalDocument);

/**
 * Returns the clinical documents the user has selected from their file system and has a
 * local error (such as zero bytes) currently stored in state.
 */
export const selectLocalErrorClinicalDocuments = (state: FileUploadState) =>
	selectClinicalDocuments(state).filter((c) => LocalErrorFileDataStatus.includes(c.status));
