import { fetchUser, setPreferredMfa } from 'state-services';
import { Entity } from 'type-declarations';
import {
	fetchAuthSession,
	fetchMFAPreference,
	setUpTOTP,
	updateMFAPreference,
	verifyTOTPSetup,
} from 'aws-amplify/auth';
import { ScopeKeyOptions, EntityType, EntityGroup } from 'type-declarations';

export const EntityTypeActions = {
	isInEntityGroup(entityType: string, entityGroup: EntityGroup): boolean {
		return (this.toEntityGroup(entityType as EntityType) & entityGroup) !== 0;
	},
	toEntityGroup(entityType: EntityType): EntityGroup {
		switch (entityType) {
			case EntityType.PROVIDER_ORGANIZATION:
				return EntityGroup.ORGANIZATION;
			case EntityType.PAYER_INSTITUTION:
				return EntityGroup.PAYER_INSTITUTION;
			case EntityType.DELEGATED_BENEFIT_MANAGER:
				return EntityGroup.DELEGATED_BENEFIT_MANAGER;
		}
	},
};

export const getTotpSeedCode = async (): Promise<string> => {
	try {
		const totpSetupDetails = await setUpTOTP();
		return totpSetupDetails.sharedSecret;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log(err);
		return '';
	}
};

export const verifyTotpCode = async (code: string, store: any): Promise<boolean> => {
	try {
		await verifyTOTPSetup({ code: code });
		await updateMFAPreference({ totp: 'PREFERRED' });
		store.dispatch(setPreferredMfa('SOFTWARE_TOKEN_MFA'));
	} catch (error) {
		// Token is not verified
		return false;
	}
	return true;
};

export const clearUserMfaPreference = async (store: any) => {
	await updateMFAPreference({ totp: 'DISABLED' });
	store.dispatch(setPreferredMfa('DISABLED'));
};

export const initializeUser = async (store: any, removePayerEntities: boolean) => {
	const [mfaPreference, authSession] = await Promise.all([
		fetchMFAPreference(),
		fetchAuthSession(),
	]);
	const preferredMfa = mfaPreference.preferred?.toString() ?? 'DISABLED';
	const idToken = authSession.tokens?.idToken?.toString() ?? '';
	store.dispatch(fetchUser({ idToken, store, removePayerEntities, preferredMfa }));
};

export const getPartnerEntityIds = (entities: Entity[]): string[] => {
	const partnerIds: string[] = [];
	entities.map((a: Entity) => {
		partnerIds.push(a.entityId);
	});
	return partnerIds;
};

export const getCapabilities = (entities: Entity[], id: string): string[] => {
	return entities.find((e) => e.entityId === id)?.capabilities || [];
};

export const getEntityTypeFromScopeKey = (scope: string): string => {
	switch (scope) {
		case ScopeKeyOptions.ORGANIZATION_ID:
			return EntityType.PROVIDER_ORGANIZATION;
		case ScopeKeyOptions.PAYER_INSTITUTION_ID:
			return EntityType.PAYER_INSTITUTION;
		case ScopeKeyOptions.DELEGATED_BENEFIT_MANAGER_ID:
			return EntityType.DELEGATED_BENEFIT_MANAGER;
		default:
			return '';
	}
};

export const getScopeKeyFromEntityType = (entityType: string): string => {
	switch (entityType) {
		case EntityType.PROVIDER_ORGANIZATION:
			return ScopeKeyOptions.ORGANIZATION_ID;
		case EntityType.PAYER_INSTITUTION:
			return ScopeKeyOptions.PAYER_INSTITUTION_ID;
		case EntityType.DELEGATED_BENEFIT_MANAGER:
			return ScopeKeyOptions.DELEGATED_BENEFIT_MANAGER_ID;
		default:
			return '';
	}
};
