import { Toolbar, Breadcrumbs, Typography } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { toDisplayString } from 'string-utilities';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadcrumbToolbar: FC = () => {
	const location = useLocation();
	const { activeEntity } = useAppSelector((state) => state.user);
	const { name } = activeEntity;
	const pathnames = location.pathname.split('/').filter((x) => x);

	return (
		<Toolbar>
			<Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
				<Typography color="secondary">{name || ''}</Typography>
				{pathnames.map((value) => {
					return (
						<Typography key={value} color="primary">
							{toDisplayString(value)}
						</Typography>
					);
				})}
			</Breadcrumbs>
		</Toolbar>
	);
};

export default BreadcrumbToolbar;
