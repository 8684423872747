import { getContrastRatio } from '@mui/material';

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
	};
}

function stringToColor(string: string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	const minContrastRatio = 4.5;
	const compareColor = '#fff';
	let contrastRatio = getContrastRatio(color, compareColor);
	if (contrastRatio < minContrastRatio) {
		const darkenBy = 20;
		while (contrastRatio < minContrastRatio) {
			color = darkenHex(color, darkenBy);
			contrastRatio = getContrastRatio(color, compareColor);
		}
	}
	return color;
}

function darkenHex(hex: string, percentage: number) {
	const rgb = hexToRgb(hex);
	const darken = (value: number) => Math.round(value * (1 - percentage / 100));
	return rgbToHex({ r: darken(rgb.r), g: darken(rgb.g), b: darken(rgb.b) });
}

function hexToRgb(hex: string) {
	hex = hex.replace('#', '');
	const parse = (start: number) => parseInt(hex.slice(start, start + 2), 16);
	return { r: parse(0), g: parse(2), b: parse(4) };
}

function rgbToHex(rgb: { r: number; g: number; b: number }) {
	const convert = (value: number) => value.toString(16).padStart(2, '0');
	return `#${convert(rgb.r)}${convert(rgb.g)}${convert(rgb.b)}`;
}
