import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { updateActiveEntity } from 'state-services';
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SelectOrganizationMenu from './SelectOrganizationMenu';
import { useNavigate } from 'react-router-dom';
import { SessionTimer } from 'rhyme-component-library';
import SecurityMenu from './SecurityMenu';
import { stringAvatar } from 'acessibility-helpers/src/uiHelpers';
import { isStatusIdleOrPending } from 'slice-utilities';
import { signOut as SignOut } from 'aws-amplify/auth';

const UserCard: FC = () => {
	const dispatch = useDispatch();
	const { firstName, lastName, activeEntity } = useAppSelector((state) => state.user);
	const { name } = activeEntity;
	const navigate = useNavigate();
	const { partners, status: partnerStatus } = useAppSelector((state) => state.partner);
	const [orgFormatted, setOrgFormatting] = useState('');

	const signOut = () => {
		SessionTimer.stopSessionTimer();
		navigate('/');
		SignOut();
	};

	useEffect(() => {
		if (!isStatusIdleOrPending([partnerStatus]) && partners.length > 0) {
			dispatch(updateActiveEntity(activeEntity));
		}
	}, []);

	useEffect(() => {
		if (name.length >= 15) {
			setOrgFormatting(name.substring(0, 17) + '...');
		} else {
			setOrgFormatting(name);
		}
	}, [name]);

	return (
		<Card variant="outlined">
			<CardHeader
				avatar={<Avatar {...stringAvatar(`${firstName} ${lastName}`)} />}
				title={firstName + ' ' + lastName}
				subheader={orgFormatted}
			/>
			<Divider />
			<CardContent sx={{ padding: 0 }}>
				<List dense>
					<SelectOrganizationMenu></SelectOrganizationMenu>
					<SecurityMenu></SecurityMenu>
					<ListItem>
						<ListItemButton onClick={signOut} dense>
							<ListItemText>Sign Out</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
};

export default UserCard;
