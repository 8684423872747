/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	EmbedDashboardUrlResponse,
	EntityType,
	PartnerEntity,
	RHYME_API_SERVICES,
} from 'type-declarations';
import { get, post } from 'aws-amplify/api';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
export interface embedUrlResponse {
	status: number;
	orgIds: [];
	payerInstitutionId: string;
}

export const getLighthouseEmbedUrl = createAsyncThunk(
	'lighthouse/getLighthouseEmbedUrl',
	async ({
		activeEntity,
		selectedPartnerEntities,
	}: {
		activeEntity: PartnerEntity;
		selectedPartnerEntities: PartnerEntity[];
	}) => {
		const urlPath = `lighthouse/${getPartnerEntityUrlPart(activeEntity)}/${activeEntity.id}/embed-url`;
		const options = {
			body: {
				organizationIds: selectedPartnerEntities.map((entity) => entity.id),
			} as unknown as FormData,
		};
		const isProviderOrganization = activeEntity.partnerType === EntityType.PROVIDER_ORGANIZATION;
		if (isProviderOrganization) {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: urlPath,
				options,
			}).response;
			const data = body.json() as unknown as Array<EmbedDashboardUrlResponse>;
			return data;
		} else {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: urlPath,
			}).response;
			const data = body.json() as unknown as Array<EmbedDashboardUrlResponse>;
			return data;
		}
	}
);

function getPartnerEntityUrlPart(activeEntity: PartnerEntity): string {
	switch (activeEntity.partnerType) {
		case EntityType.PAYER_INSTITUTION:
			return 'payer-institution';
		case EntityType.DELEGATED_BENEFIT_MANAGER:
			return 'delegated-benefit-manager';
		case EntityType.PROVIDER_ORGANIZATION:
		default:
			return 'organization';
	}
}
