/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RHYME_API_SERVICES, PartnerEntity } from 'type-declarations';
import { get } from 'aws-amplify/api';
import { loadActiveEntityDetails } from 'state-services';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchPartners = createAsyncThunk(
	'partners/fetchPartners',
	async (
		{
			partnerIds,
			activeEntityId,
		}: {
			partnerIds: string[];
			activeEntityId: string;
		},
		{ dispatch }
	) => {
		try {
			const responses = await Promise.all(
				partnerIds.map(async (partnerId) => {
					const { body } = await get({ apiName: rhymeApiUrl, path: `partner-entity/${partnerId}` })
						.response;
					return (await body.json()) as PartnerEntity;
				})
			).catch((err) => {
				console.warn(err);
				return [];
			});

			// Map the responses to extract the entity data
			const entities = responses.map((response) => response);

			//find active entity using the action.activeEntityId
			const activeEntity: PartnerEntity =
				entities.find((p) => p.id === activeEntityId) ?? ({} as PartnerEntity);
			//load the users current activeEntity details
			dispatch(loadActiveEntityDetails({ ...activeEntity }));
			return entities as PartnerEntity[];
		} catch (error) {
			console.warn(error);
		}
	}
);
