/*
- Sets & returns browser page title.
- https://www.w3.org/WAI/WCAG21/quickref/?currentsidebar=%23col_overview&levels=aaa#page-titled
*/
export function setDocumentTitle(title: string): string {
	document.title = title || 'Rhyme';
	return document.title;
}
/*
- Resets page focus to top of page.
- https://www.w3.org/WAI/WCAG21/quickref/?currentsidebar=%23col_overview&levels=aaa&showtechniques=132#focus-order
*/
export function resetPageFocus(): void {
	document.body.focus();
}
