import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get } from 'aws-amplify/api';
import * as Sentry from '@sentry/react';
import { RHYME_API_SERVICES, SliceStatus, User } from 'type-declarations';
import { combineAndParseEntities, decodeJwtBody, getActiveEntityDetails } from './utilities';
import { getPartnerEntityIds } from 'user-utilities';
import { Entity, PartnerEntity } from 'type-declarations';
import { fetchPartners } from '../partner/thunks';
import { UserState } from './slice';

const authorizationServiceUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchUser = createAsyncThunk(
	'user/save_token_and_user',
	async ({
		idToken,
		store,
		removePayerEntities,
		preferredMfa,
	}: {
		idToken: string;
		store: any;
		removePayerEntities: boolean;
		preferredMfa: string;
	}) => {
		try {
			const { body } = await get({
				apiName: authorizationServiceUrl,
				path: `authorization/user`,
			}).response;
			const data = (await body.json()) as User;
			const { email, sub, family_name, given_name } = decodeJwtBody(idToken);
			const entities = combineAndParseEntities(data);
			const activeEntity = getActiveEntityDetails(entities, removePayerEntities);
			const partnerEntityIds = getPartnerEntityIds(entities);
			const { activePartnerEntityId, partnerEntityType } = activeEntity;

			const userDetails: UserState = {
				lastName: family_name,
				firstName: given_name,
				userName: email,
				id: sub || '',
				activeEntity: {} as PartnerEntity,
				entities: entities ?? ([] as Entity[]),
				idToken,
				preferredMfa,
				isRso: false,
				status: SliceStatus.SUCCEEDED,
			};

			Sentry.setUser({ email: email });
			Sentry.setTag('orgID', activePartnerEntityId);
			Sentry.setTag('activePartnerEntityId', activePartnerEntityId);
			Sentry.setTag('activePartnerEntityType', partnerEntityType);

			if (partnerEntityIds.length > 0) {
				store.dispatch(
					fetchPartners({
						partnerIds: partnerEntityIds,
						activeEntityId: activePartnerEntityId,
					})
				);
			}
			return userDetails;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(`Fetch user Failed with status: ${statusCode}`);
			}
			return {} as UserState;
		}
	}
);
