import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSurveyContinuation } from './thunks';
import { SliceStatus, SdgFhirClinicalAttestationResponse } from 'type-declarations';

export interface SurveyContinuationState {
	status: SliceStatus;
	survey: SdgFhirClinicalAttestationResponse | null;
}

export const initialSurveyContinuationState: SurveyContinuationState = {
	status: SliceStatus.IDLE,
	survey: {
		fhirQuestionnaire: '',
	},
};

export const surveyContinuationSlice = createSlice({
	name: 'surveyContinuation',
	initialState: initialSurveyContinuationState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchSurveyContinuation.pending, (state) => {
			Object.assign(state, initialSurveyContinuationState);
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(
			fetchSurveyContinuation.fulfilled,
			(state, action: PayloadAction<SdgFhirClinicalAttestationResponse | null>) => {
				state.status = SliceStatus.SUCCEEDED;
				state.survey = action.payload;
			}
		);
		builder.addCase(fetchSurveyContinuation.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
	},
});

export const surveyContinuationReducer = surveyContinuationSlice.reducer;
export const surveyContinuationActions = surveyContinuationSlice.actions;
