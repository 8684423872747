export * from './provider/default-contact';
export * from './provider/facility-rules';
export * from './partner/users';
export * as partner from './partner/users';

import { combineReducers } from '@reduxjs/toolkit';
import { partnerUsersReducer } from './partner/users';
import { providerDefaultContactReducer } from './provider/default-contact';
import { providerFacilityReducer } from './provider/facility-rules/slice';

export const combineProviderReducers = combineReducers({
	defaultContact: providerDefaultContactReducer,
	facilityRules: providerFacilityReducer,
});

export const combinePartnerReducers = combineReducers({
	users: partnerUsersReducer,
});

export const combineAdminReducers = combineReducers({
	partner: combinePartnerReducers,
	provider: combineProviderReducers,
});
