import { Referral, ValidationError } from 'type-declarations';

/**
 * Corrects all known formatting issues with the backend ValidationErrors.
 */
export function fixBackendValidationErrorIssues(validationErrors: ValidationError[]) {
	return fixPatientStateRelatedToIssue(fixNpiRelatedToCasingIssue(validationErrors));
}

/** BACKEND JUNK
 * There's an issue in the backend where some of the errors related to NPI errors are
 * sent with the key "Npi" instead of lower-cased "npi". This fixes that.
 */
function fixNpiRelatedToCasingIssue(validationErrors: ValidationError[]) {
	if (validationErrors && validationErrors.length > 0) {
		validationErrors.forEach((error) => {
			if (error.relatedTo.endsWith('.Npi')) {
				error.relatedTo = error.relatedTo.replace('.Npi', '.npi');
			}
		});
	}
	return validationErrors;
}

/** BACKEND JUNK
 * There's an issue in the backend where the referral errors related to the patient.state field
 * are sent with the relatedTo key "patientState" instead of the expected "patient.state" key.
 * This fixes that.
 */
function fixPatientStateRelatedToIssue(validationErrors: ValidationError[]) {
	if (validationErrors && validationErrors.length > 0) {
		validationErrors.forEach((error) => {
			if (error.relatedTo == 'patientState') {
				error.relatedTo = error.relatedTo.replace('patientState', 'patient.state');
			}
		});
	}
	return validationErrors;
}

/* BACKEND JUNK
 * There's an issue in the backend where validation on the referral fails if the
 * contact property is on the referral received by the draft. This function is to account for this issue,
 * and is similar to how it is handled in vue-tachyon
 */
export function removeContactFromReferral(referral: Referral): Referral {
	if (referral.contact) {
		const { contact, ...referralWithoutContact } = referral;
		return referralWithoutContact;
	}
	return referral;
}
