export enum ScopeKeyOptions {
	ORGANIZATION_ID = 'organizationId',
	PAYER_INSTITUTION_ID = 'payerInstitutionId',
	DELEGATED_BENEFIT_MANAGER_ID = 'delegatedBenefitManagerId',
}

export enum EntityType {
	PROVIDER_ORGANIZATION = 'ProviderOrganization',
	PAYER_INSTITUTION = 'PayerInstitution',
	DELEGATED_BENEFIT_MANAGER = 'DelegatedBenefitManager',
}

// NOTE: values must be power of 2 so that we can use these as flags
export enum EntityGroup {
	ORGANIZATION = 1,
	PAYER_INSTITUTION = 2,
	DELEGATED_BENEFIT_MANAGER = 4,
	NOT_ORGANIZATION = PAYER_INSTITUTION | DELEGATED_BENEFIT_MANAGER,
}

export type EntityOption = {
	id: string;
	name: string;
	entityType: string;
};
