import * as z from 'zod';

export interface FacilityFormData {
	npi: string;
	name: string;
	address1: string;
	city: string;
	zip: string;
	state: string;
	isEnabled: boolean;
	ruleNpi: boolean;
	ruleName: boolean;
	ruleAddress: boolean;
	destinationId?: string;
}
const UsPostalCodeRegex = new RegExp(/^\d{5}$|^\d{5}-\d{4}$/);

/**
 * Zod - is a Typescript-first library for making validation schemas
 * https://github.com/colinhacks/zod
 */
export const facilityFormSchema = z.object({
	npi: z.string().regex(/^\d{10}$/, 'Please enter valid NPI'),
	name: z
		.string()
		.min(1, 'Please enter facility name')
		.max(128, 'Please enter facility name under 128 characters'),
	address1: z.string().min(1, 'Please enter street address'),
	city: z.string().min(1, 'Please enter city'),
	zip: z.string().regex(UsPostalCodeRegex, 'Please enter a valid zip code'),
	state: z.string().min(2, 'Please select state'),
	isEnabled: z.boolean().optional(),
	ruleNpi: z.boolean().optional(),
	ruleName: z.boolean().optional(),
	ruleAddress: z.boolean().optional(),
	destinationId: z.string().optional(),
});
