import { ExtendedFileData } from 'type-declarations';

export const setHeaderWithAmzTagging = (f: ExtendedFileData, organizationId: string) => ({
	headers: {
		'Content-Type': 'application/pdf',
		'x-amz-meta-documentname': f.name,
		'x-amz-meta-documenttype': f.documentType,
		'x-amz-tagging': `DataOwnerOrganizationId=${organizationId}`,
	},
});

export const setHeaderWithoutAmzTagging = (f: ExtendedFileData) => ({
	headers: {
		'Content-Type': 'application/pdf',
		'x-amz-meta-documentname': f.name,
		'x-amz-meta-documenttype': f.documentType,
	},
});
