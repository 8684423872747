import { ValidationError } from 'utilities/standard-responses/standardResponses';
import { Referral } from '../case/models';

export enum DraftStatus {
	Incomplete = 'INCOMPLETE',
	Submitted = 'SUBMITTED',
	Pending = 'PENDING',
	Reprocessing = 'REPROCESSING',
	Unsupported = 'UNSUPPORTED',
}

export interface DraftModel {
	attemptedBenefitManagerIds: Array<string>;
	benefitManagerId: string;
	benefitManagerIds: Array<string>;
	createdDate: string;
	errors: Array<ValidationError>;
	modifiedDate: string;
	organizationId: string;
	panCaseId: string;
	partnerCaseId: string;
	precheckPassed: boolean;
	referral: Referral;
	routeId: string;
	status: DraftStatus;
	transactionType: string;
	version: number;
}
