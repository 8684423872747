import { createSlice } from '@reduxjs/toolkit';
import { FacilityRule, RenderingFacility, SliceStatus } from 'type-declarations';
import {
	fetchProviderFacilities,
	fetchProviderFacilityRule,
	validateProviderFacilityRule,
} from './thunks';

export interface FacilityRulesState {
	status: SliceStatus;
	facilityAddresses: Array<Object>;
	facilities: Array<RenderingFacility>;
	rule: FacilityRule;
	duplicateOrConflict: boolean;
	createdFacilityProviderId: string;
}

const initialState: FacilityRulesState = {
	status: SliceStatus.IDLE,
	facilityAddresses: [],
	facilities: [],
	rule: {} as FacilityRule,
	duplicateOrConflict: false,
	createdFacilityProviderId: '',
};

export const providerFacilitySlice = createSlice({
	name: 'providerFacilityThunk',
	initialState,
	reducers: {
		restFacilities: (state) => {
			Object.assign(state, initialState);
		},
		setFacilityRule: (state, action) => {
			state.rule = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProviderFacilities.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchProviderFacilities.fulfilled, (state, action) => {
				state.status = SliceStatus.SUCCEEDED;
				state.facilities = action.payload;
			}),
			builder.addCase(fetchProviderFacilities.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			}),
			builder.addCase(fetchProviderFacilityRule.pending, (state) => {
				if (state.status === SliceStatus.IDLE) {
					state.status = SliceStatus.PENDING;
				} else state.status = SliceStatus.REFRESHING;
			}),
			builder.addCase(fetchProviderFacilityRule.fulfilled, (state, action) => {
				state.status = SliceStatus.SUCCEEDED;
				state.rule = action.payload;
			}),
			builder.addCase(fetchProviderFacilityRule.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			}),
			builder.addCase(validateProviderFacilityRule.pending, (state) => {
				state.duplicateOrConflict = false;
				if (state.status === SliceStatus.IDLE) {
					state.status = SliceStatus.PENDING;
				} else state.status = SliceStatus.REFRESHING;
			}),
			builder.addCase(validateProviderFacilityRule.fulfilled, (state, action) => {
				state.status = SliceStatus.SUCCEEDED;
				state.duplicateOrConflict = action.payload;
			}),
			builder.addCase(validateProviderFacilityRule.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			});
	},
});

export const providerFacilityReducer = providerFacilitySlice.reducer;
export const providerFacilityActions = providerFacilitySlice.actions;
export const { restFacilities, setFacilityRule } = providerFacilityActions;
