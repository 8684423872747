/* eslint-disable no-unused-vars */
import { fetchAuthSession } from 'aws-amplify/auth';
import { ServiceEnumRoute } from 'type-declarations';

//When adding a service to the API Endpoints, add an entry to RHYME_SERVICES enum where the key
//matches the string it is set to.
//Add a record to BackendRoutes using the key from RhymeServices and assign a string for the service's URL.
// export enum RHYME_SERVICES {
//   CaseService = "CaseService"
// }

// const BACKEND_ROUTES: Record<RHYME_SERVICES, string> = {
//   CaseService: `https://case.${process.env.REACT_APP_ENV}.priorauthnow.com/jwt/`,
// }

//-------------------------------------------------------------------------------------//
//getAuthHeaders and AmplifyApiEndpoints DO NOT need to be changed to add most services.

/**
 * when a user signins with rso the token is saved in localStorage
 * check if localStorage has idToken before attempting to get auth.currentSession token
 * @returns object
 */
export const getAuthHeaders = async () => {
	try {
		const rsoLocalStorageToken = localStorage.getItem('idToken');
		const idToken = rsoLocalStorageToken ?? (await fetchAuthSession())?.tokens?.idToken?.toString();
		return { Authorization: `${idToken}` };
	} catch {
		return {};
	}
};

export const RHYME_API_ENDPOINTS = (routes: ServiceEnumRoute) => {
	return Object.fromEntries(
		Object.entries(routes).map(([key, value]) => [
			key,
			{ endpoint: value, region: process.env.REACT_APP_REGION },
		])
	);
};
