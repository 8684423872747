import { Chip } from '@mui/material';
import { FC } from 'react';
import { getDescriptiveRolesByRoleStrings, RhymeUser } from 'type-declarations';

type Props = {
	user: RhymeUser;
};

const UserRoleChips: FC<Props> = ({ user }) => {
	const userDescriptiveRoles = getDescriptiveRolesByRoleStrings(user?.roles ?? []);
	return (
		<>
			{userDescriptiveRoles.map((dr, index) => (
				<Chip label={dr.display} key={index} sx={{ marginRight: 1 }} />
			))}
		</>
	);
};

export default UserRoleChips;
