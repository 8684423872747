import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get } from 'aws-amplify/api';
import { PanPayerIdList, PayerEntry, RHYME_API_SERVICES } from 'type-declarations';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];

export const fetchPayerList = createAsyncThunk('payerlist-thunk/fetchPayerListThunk', async () => {
	try {
		const { body } = await get({
			apiName: rhymeApiUrl,
			path: `ontology/payer/flat`,
		}).response;
		const data = (await body.json()) as unknown as PayerEntry[];
		return data;
	} catch (error) {
		if (error instanceof ApiError && error.response) {
			const { statusCode, body } = error.response;
			if (validResponseCodes.includes(statusCode) && body) {
				// eslint-disable-next-line no-console
				console.warn('fetchPayerList failed with error:', JSON.parse(body));
			} else {
				// eslint-disable-next-line no-console
				console.warn('fetchPayerList failed with error:', error);
			}
			//// fallback in case of an error... this shouldn't happen, but we're logging a warning above if it does
			return PanPayerIdList;
		}
		throw new Error(`Fetch payer list failed with error: ${error}`);
	}
});
