import { createSlice } from '@reduxjs/toolkit';
import { DefaultContact, SliceStatus } from 'type-declarations';
import { createProviderDefaultContact, fetchProviderDefaultContact } from './thunks';

export interface DefaultContactState {
	status: SliceStatus;
	defaultContact: DefaultContact;
}

const initialState: DefaultContactState = {
	status: SliceStatus.IDLE,
	defaultContact: {} as DefaultContact,
};

export const providerDefaultContactSlice = createSlice({
	name: 'providerDefaultContactsThunk',
	initialState,
	reducers: {
		resetDefaultContact: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProviderDefaultContact.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(fetchProviderDefaultContact.fulfilled, (state, action) => {
				state.status = SliceStatus.SUCCEEDED;
				state.defaultContact = action.payload;
			}),
			builder.addCase(fetchProviderDefaultContact.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			}),
			builder.addCase(createProviderDefaultContact.pending, (state) => {
				state.status = SliceStatus.REFRESHING;
			}),
			builder.addCase(createProviderDefaultContact.fulfilled, (state) => {
				state.status = SliceStatus.SUCCEEDED;
			}),
			builder.addCase(createProviderDefaultContact.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
			});
	},
});

export const providerDefaultContactReducer = providerDefaultContactSlice.reducer;
export const providerDefaultContactActions = providerDefaultContactSlice.actions;
export const { resetDefaultContact } = providerDefaultContactActions;
