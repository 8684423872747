import { Organization, PartnerEntity } from 'type-declarations';

//TODO: remove this when auth integration is done
export const convertOrgToEntity = (orgDetails: Organization): Partial<PartnerEntity> => {
	return {
		id: orgDetails.organizationId,
		name: orgDetails.name,
		noticeOfAdmissionEnabled: orgDetails.noticeOfAdmissionEnabled,
		isLighthouseEnabled: orgDetails.isLighthouseEnabled
	};
};
