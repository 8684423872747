import { createSlice, PayloadAction, current, SerializedError } from '@reduxjs/toolkit';
import { getUuid } from 'string-utilities';
import { Span, Transaction } from '@sentry/react';
import * as SentryIo from 'sentry-utilities';
import { EmbedDashboardUrlResponse, PartnerEntity } from 'type-declarations';
import { getLighthouseEmbedUrl } from './thunks';
import { getAndSortDashboardsFromEmbedUrls } from './utilities';

export const initialLighthouseState: LighthouseState = {
	portalSessionId: getUuid(),
	sentryTransaction: null,
	currentDashboardSentrySpan: null,
	hasError: false,
	errors: [] as SerializedError[],
	embedUrlsDetails: [] as EmbedDashboardUrlResponse[],
	selectedPartnerEntities: [] as PartnerEntity[],
	selectedDashboardId: null,
	printDashboardIds: [] as string[],
	resetDashboardIds: [] as string[],
};

export interface LighthouseState {
	portalSessionId: string;
	sentryTransaction: Transaction | null;
	currentDashboardSentrySpan: Span | null;
	hasError: boolean;
	errors: SerializedError[];
	embedUrlsDetails: EmbedDashboardUrlResponse[];
	selectedPartnerEntities: PartnerEntity[];
	selectedDashboardId: string | null;
	printDashboardIds: string[];
	resetDashboardIds: string[];
}

export const lighthouseSlice = createSlice({
	name: 'lighthouse',
	initialState: initialLighthouseState,
	reducers: {
		changeLighthouseDashboard: (state, action: PayloadAction<string>) => {
			const currentState = current(state);
			const newDashboardId = action.payload;
			const dashboardId = action.payload;
			const dashboard = currentState.embedUrlsDetails
				.flatMap((embed) => embed.dashboards)
				.find((db) => db.dashboardId === dashboardId)!;

			/* State is an immer proxy object which is revoked once the microreducer completes.
       Scenario exists where changeDashboard updates occur after state proxy is revoked &
       produces error "Cannot perform 'get' on a proxy". The "current" function provides
       a read-only copy of the state to check against even after proxy has been revoked. */
			if (currentState.currentDashboardSentrySpan) {
				SentryIo.endSpan(currentState.currentDashboardSentrySpan);
			}
			const span = dashboard.tabText
				? SentryIo.startLighthouseDashboardSpan(currentState.sentryTransaction!, dashboard)
				: null;

			state.currentDashboardSentrySpan = span;
			state.selectedDashboardId = newDashboardId;
		},
		closeLighthouse: (state) => {
			if (state.currentDashboardSentrySpan) {
				SentryIo.endSpan(state.currentDashboardSentrySpan);
			}
			if (state.sentryTransaction) {
				SentryIo.finishTransaction(state.sentryTransaction, 'ok');
			}
			state.embedUrlsDetails = [];
			state.sentryTransaction = null;
			state.currentDashboardSentrySpan = null;
			state.selectedDashboardId = null;
			state.printDashboardIds = [];
			state.resetDashboardIds = [];
			state.hasError = false;
		},
		resetLighthouseDashboard: (state, action) => {
			state.resetDashboardIds = [...state.resetDashboardIds, action.payload];
		},
		printLighthouseDashboard: (state, action) => {
			state.printDashboardIds = [...state.printDashboardIds, action.payload];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getLighthouseEmbedUrl.pending, (state, action) => {
			state.embedUrlsDetails = [];
			state.selectedPartnerEntities = action.meta.arg.selectedPartnerEntities;

			if (state.currentDashboardSentrySpan) {
				SentryIo.endSpan(state.currentDashboardSentrySpan);
			}
			if (state.sentryTransaction) {
				SentryIo.finishTransaction(state.sentryTransaction, 'ok');
			}
		});
		builder.addCase(getLighthouseEmbedUrl.fulfilled, (state, action) => {
			//update Lighthouse state
			state.embedUrlsDetails = action.payload;
			const sortedDashboards = getAndSortDashboardsFromEmbedUrls(action.payload);
			const selectedDashboard = sortedDashboards[0];
			state.selectedDashboardId = selectedDashboard.dashboardId;

			const lighthousePageSessionId = getUuid();
			const transaction = SentryIo.startLighthouseTransaction(
				state.portalSessionId,
				lighthousePageSessionId
			);
			state.sentryTransaction = transaction;
			const span = SentryIo.startLighthouseDashboardSpan(transaction, selectedDashboard);
			state.currentDashboardSentrySpan = span;
		});
		builder.addCase(getLighthouseEmbedUrl.rejected, (state, action) => {
			if (action.error.message !== 'Aborted') {
				state.hasError = true;
				state.errors = [...state.errors, action.error];
				state.embedUrlsDetails = [];
			}
		});
	},
});

export const lighthouseReducer = lighthouseSlice.reducer;
export const lighthouseActions = lighthouseSlice.actions;
export const {
	changeLighthouseDashboard,
	closeLighthouse,
	resetLighthouseDashboard,
	printLighthouseDashboard,
} = lighthouseActions;
