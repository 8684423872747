import * as z from 'zod';

const phoneRegex = new RegExp(/^\d{10}$|^\d{3}-\d{3}-\d{4}$/);

export const defaultContactFormSchema = z.object({
	name: z.string().min(1, 'Name is required.'),
	email: z.string().min(1, 'Email address is required.').email('Invalid email address.'),
	fax: z
		.string()
		.min(1, 'Fax number is required.')
		.regex(phoneRegex, 'Fax number must be in the format 999-999-9999 or 9999999999.'),
	phone: z
		.string()
		.min(1, 'Phone number is required.')
		.regex(phoneRegex, 'Phone number must be in the format 999-999-9999 or 9999999999.'),
});
