import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get } from 'aws-amplify/api';
import { OrgHistoryModel, RHYME_API_SERVICES } from 'type-declarations';

const validResponseCodes = [200, 404];
const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchCaseHistory = createAsyncThunk(
	'history-thunk/fetchCaseHistoryThunk',
	async ({ activeEntityId, caseId }: { activeEntityId: string; caseId: string }) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${activeEntityId}/entity/${caseId}/event-type/external`,
			}).response;
			const data = (await body.json()) as unknown as OrgHistoryModel;
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(
					`Response status of ${statusCode}, History Service expects${validResponseCodes.reduce(
						(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
						''
					)}.`
				);
			}
			throw new Error(`Fetch draft failed with error: ${error}`);
		}
	}
);
