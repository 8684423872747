import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get, post } from 'aws-amplify/api';
import { NoaRecord, NoticeOfAdmission, RHYME_API_SERVICES } from 'type-declarations';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];
const validationResponseCodes = [200, 404, 400];

export const fetchNoaSubmissionDetails = createAsyncThunk(
	'caseThunk/fetchNoaDetails',
	async ({ orgId, caseId }: { orgId: string; caseId: string }) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/noa/record`,
			}).response;
			const data = await body.json();
			return data as unknown as NoaRecord;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(
					`Response status of ${statusCode}, 
        transaction service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Fetch noa details failed with error: ${error}`);
		}
	}
);

//TODO: currently not used, can possibly be removed?
export const noaValidation = createAsyncThunk(
	'caseThunk/noaValidation',
	async ({
		orgId,
		caseId,
		noaData,
		submissionId,
	}: {
		orgId: string;
		caseId: string;
		noaData: NoticeOfAdmission;
		submissionId: string;
	}) => {
		const options = {
			body: { DataToValidate: noaData } as unknown as FormData,
		};
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/validate`,
				options,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validationResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        data gathering service expects${validationResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Noa validation failed with error: ${error}`);
		}
	}
);

export const submitNoa = createAsyncThunk(
	`caseThunk/submitNoa`,
	async ({
		orgId,
		caseId,
		noaData,
		submissionId,
		screen,
	}: {
		orgId: string;
		caseId: string;
		noaData: NoticeOfAdmission;
		submissionId: string;
		screen: string;
	}) => {
		const options = {
			body: { DataGatheringScreen: screen, SubmittedData: noaData } as unknown as FormData,
		};

		try {
			await post({
				apiName: rhymeApiUrl,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/submit`,
				options,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        data gathering service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			throw new Error(`Submit noa failed with error: ${error}`);
		}
	}
);
