import Admin from '@app/views/admin/Admin';
import ErrorNotFound from '@app/views/errorNotFound/ErrorNotFound';
import Lighthouse from '@app/views/lighthouse/Lighthouse';
import { Suspense } from 'react';
import { PrivateRoute } from './PrivateRoutes';
import App from '@src/App';
import { UserCapabilities } from '@app/shared/constants';

export interface ApplicationPages {
	path: string;
	element: React.ReactNode;
	errorElement: React.ReactNode;
	requiredCapabilities: string[];
}

// TODO: requirements here are GetLighthouseDashboard bc App is just <Lighthouse />
export const homePage: ApplicationPages = {
	path: '/',
	element: (
		<Suspense fallback={<div>Loading....</div>}>
			<PrivateRoute capabilitiesWithPermission={[UserCapabilities.GetLighthouseDashboard]}>
				<App></App>
			</PrivateRoute>
		</Suspense>
	),
	errorElement: <ErrorNotFound></ErrorNotFound>,
	requiredCapabilities: [UserCapabilities.GetLighthouseDashboard],
};

export const adminPage: ApplicationPages = {
	path: '/admin',
	element: (
		<Suspense fallback={<div>Loading....</div>}>
			<PrivateRoute
				capabilitiesWithPermission={[UserCapabilities.GetUser, UserCapabilities.GrantUserRole]}
			>
				<Admin />
			</PrivateRoute>
		</Suspense>
	),
	errorElement: <ErrorNotFound></ErrorNotFound>,
	requiredCapabilities: [UserCapabilities.GetUser, UserCapabilities.GrantUserRole],
};

export const lighthousePage: ApplicationPages = {
	path: '/lighthouse',
	element: (
		<Suspense fallback={<div>Loading....</div>}>
			<PrivateRoute capabilitiesWithPermission={[UserCapabilities.GetLighthouseDashboard]}>
				<Lighthouse />
			</PrivateRoute>
		</Suspense>
	),
	errorElement: <ErrorNotFound></ErrorNotFound>,
	requiredCapabilities: [UserCapabilities.GetLighthouseDashboard],
};
