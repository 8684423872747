/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	ExtendedFileData,
	FileDataStatus,
	NoteResponse,
	RHYME_API_SERVICES,
	UploadFileURIResponseObject,
} from 'type-declarations';
import { DocumentType, FileData, UploadFileURIRequest } from 'type-declarations';
import { SnackbarManager } from 'rhyme-component-library';
import Axios, { AxiosError } from 'axios';
import { setHeaderWithAmzTagging, setHeaderWithoutAmzTagging } from './utils/setRequestHeader';
import { del, post } from 'aws-amplify/api';
import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

async function uploadClinicalFiles(
	organizationId: string,
	caseId: string,
	filesToUpload: Array<ExtendedFileData>,
	batchId: string
): Promise<Array<ExtendedFileData>> {
	if (!filesToUpload || !(filesToUpload.length > 0)) {
		return [];
	}
	const fileUploadUrls = (
		await getFileUploadUris(
			organizationId,
			caseId,
			filesToUpload.map((file) => file.systemFile!),
			batchId
		)
	).files;

	//After we get all upload URLs, we associate each URL returned to the correct
	//ExtendedFileData object in our ExtendedFileData array by matching the name
	//returned in the URI response.
	filesToUpload.forEach((fileToUpload) => {
		const matchingUriResponse = fileUploadUrls.find(
			(urlResp) => urlResp.documentName == fileToUpload.name
		);
		if (matchingUriResponse) {
			fileToUpload.status = FileDataStatus.INITIAL;
			fileToUpload.uri = matchingUriResponse.uri;
			fileToUpload.fileId = matchingUriResponse.fileId;
		}
	});

	const uploadedFiles = await Promise.all(
		filesToUpload.map(async (f) => uploadFileToBucket(f, organizationId))
	);

	return uploadedFiles;
}

async function getFileUploadUris(
	organizationId: string,
	caseId: string,
	fileArray: File[],
	batchId: string
): Promise<UploadFileURIResponseObject> {
	const request: UploadFileURIRequest = {
		files: fileArray.map((f) => ({
			documentName: f!.name,
			documentType: DocumentType.ClinicalDocument,
		})),
		batchId: batchId,
	};
	const options = {
		body: { ...request } as unknown as FormData,
	};
	const { body: responseBody } = await post({
		apiName: rhymeApiUrl,
		path: `organization/${organizationId}/entity/${caseId}/upload-url`,
		options,
	}).response;
	return (await responseBody.json()) as unknown as UploadFileURIResponseObject;
}

async function uploadFileToBucket(
	extendedFileData: ExtendedFileData,
	organizationId: string
): Promise<ExtendedFileData> {
	const response = await Axios.put(
		extendedFileData.uri,
		extendedFileData.systemFile,
		extendedFileData.uri.includes('x-amz-tagging')
			? setHeaderWithAmzTagging(extendedFileData, organizationId)
			: setHeaderWithoutAmzTagging(extendedFileData)
	).catch((err: AxiosError) => {
		console.error('Error occured when uploading file to S3 bucket.', {
			message: err.message,
			code: err.code,
			cause: err.cause,
			name: err.name,
			documentName: extendedFileData.name,
			documentSize: extendedFileData.systemFile?.size,
			fileId: extendedFileData.fileId,
			responseStatus: err.response?.status,
			requestDomain: err.config?.url?.split('?')[0],
		});
		Sentry.captureException(err);
		return err;
	});
	extendedFileData.status = response.status == 200 ? FileDataStatus.INITIAL : FileDataStatus.ERROR;
	return extendedFileData;
}

async function uploadClinicalNote(
	organizationId: string,
	caseId: string,
	clinicalNote: ExtendedFileData | null,
	batchId: string
): Promise<ExtendedFileData | null> {
	if (!clinicalNote || !clinicalNote.clinicalNoteText) {
		return null;
	}
	const options = {
		body: { clinicalNote: clinicalNote.clinicalNoteText, batchId },
	};
	try {
		const { body: responseBody } = await post({
			apiName: rhymeApiUrl,
			path: `organization/${organizationId}/case/${caseId}/clinical-note`,
			options,
		}).response;
		const noteResponse = (await responseBody.json()) as unknown as NoteResponse;
		clinicalNote.fileId = noteResponse.clinicalNoteFileId;
		clinicalNote.status = FileDataStatus.UPLOADED;
		return clinicalNote;
	} catch (error) {
		clinicalNote.status = FileDataStatus.ERROR;
		return clinicalNote;
	}
}

async function updateFileDataOnCase(
	organizationId: string,
	caseId: string,
	filesToUpdate: ExtendedFileData[]
) {
	const request = filesToUpdate.map((f) => {
		return { ...f, systemFile: undefined, uri: undefined, clinicalNoteText: undefined };
	});
	const options = {
		body: { files: request } as unknown as FormData,
	};

	try {
		const { body } = await post({
			apiName: rhymeApiUrl,
			path: `organization/${organizationId}/case/${caseId}/file`,
			options,
		}).response;
		const data = await body.json();
		return data;
	} catch (error) {
		console.warn(error);
		return null;
	}
}

export const uploadClinicalInformation = createAsyncThunk(
	'file-upload/uploadClinicalInformation',
	async (
		{
			filesToUpload,
			caseId,
			organizationId,
		}: {
			filesToUpload: Array<ExtendedFileData>;
			caseId: string;
			organizationId: string;
		},
		{ rejectWithValue }
	) => {
		const batchId = uuidv4();
		const clinicalDocuments = filesToUpload.filter(
			(file) => file.documentType == DocumentType.ClinicalDocument
		);
		const clinicalNote = filesToUpload.find(
			(file) => file.documentType == DocumentType.ClinicalNote
		);
		const responses = await Promise.all([
			uploadClinicalFiles(organizationId, caseId, clinicalDocuments, batchId),
			uploadClinicalNote(organizationId, caseId, clinicalNote ?? null, batchId),
		]);

		const uploadedFiles = responses.flat().filter((file) => file != null);
		//If any S3 uploads failed, we have to cancel every file on the batch to give
		//the user the chance to re-upload.
		const anyFilesFailed = uploadedFiles.some(
			(uploadedFile) => uploadedFile.status == FileDataStatus.ERROR
		);
		if (anyFilesFailed) {
			uploadedFiles.forEach((f) => (f.status = FileDataStatus.CANCELED));
			//Waiting 1.5 seconds before updating all files to CANCELED because
			//there is a race condition in the network where sometimes the files
			//are not yet saved to the case.
			await new Promise((resolve) => setTimeout(resolve, 1500));
			await updateFileDataOnCase(organizationId, caseId, uploadedFiles);
			return rejectWithValue(uploadedFiles);
		}
		return uploadedFiles;
	}
);

// Updating unscuccessful files status to canceled
export const submissionFailure = createAsyncThunk(
	'file-upload/submissionFailure',
	async ({
		canceledFiles,
		caseId,
		organizationId,
	}: {
		canceledFiles: FileData[];
		caseId: string;
		organizationId: string;
	}) => {
		const options = {
			body: { files: canceledFiles } as unknown as FormData,
		};

		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/case/${caseId}/file`,
				options,
			}).response;
			const data = await body.json();
			return data;
		} catch (error) {
			console.warn(error);
			return null;
		}
	}
);

// Detach file with fileId
export const detachFile = createAsyncThunk(
	'file-upload/detachFile',
	async ({
		fileId,
		organizationId,
		caseId,
	}: {
		fileId: string;
		organizationId: string;
		caseId: string;
	}) => {
		try {
			const delOperation = await del({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/case/${caseId}/file/${fileId}`,
			});
			await delOperation.response;
		} catch (error) {
			console.warn(error);
			SnackbarManager.show({
				message: `An Error occurred when attempting to delete a file.`,
				type: 'error',
			});
			return null;
		}
	}
);
