import SidebardNav from '@app/shared/components/sidebarNav/SidebarNav';
import { Outlet } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SnackbarManager } from 'rhyme-component-library';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { SessionTimer, SessionTimerNotification, CustomMessage } from 'rhyme-component-library';
import { initializeUser } from 'user-utilities';
import store from '@app/store';

//This component initializes needed resources for the react-portal application.
//While resources are loading, a Loading screen is displayed. Once all resources
//have loaded, the application is displayed to the user.
const ApplicationWrapper: FC = () => {
	const { id: activeEntityId } = useAppSelector((state) => state.user.activeEntity);
	//if the ID is not set on the active entity object, we have not
	//yet loaded the partner object and should continue showing the
	//Loading screen.
	const isLoading = !activeEntityId;

	const [showModal, setShowModal] = useState(SessionTimer.getShowModal());
	useEffect(() => {
		const initializeUserOnMount = async () => {
			await initializeUser(store, false);
		};
		initializeUserOnMount();
		// Start timer when App mounts
		SessionTimer.startSessionTimer();

		// Add a listener to the SingletonService to update showModal state
		const showModalChangeListener = (newShowModal: boolean) => {
			setShowModal(newShowModal);
		};
		SessionTimer.addShowModalChangeListener(showModalChangeListener);

		// Clean up the listener when the component unmounts
		return () => {
			SessionTimer.removeShowModalChangeListener(showModalChangeListener);
		};
	}, []);

	return isLoading ? (
		<CustomMessage title="Loading..." loading={isLoading} />
	) : (
		<Box
			sx={{ display: 'flex', width: '100%', height: '100%' }}
			onMouseMove={() => SessionTimer.resetSessionTimer()}
			onKeyUp={() => SessionTimer.resetSessionTimer()}
		>
			<SidebardNav />
			<Box component="main" sx={{ width: '100%', flexGrow: 1, height: '100%' }}>
				<Outlet />
			</Box>
			<SessionTimerNotification showModal={showModal} />
			<SnackbarManager />
		</Box>
	);
};

export default ApplicationWrapper;
