import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileDownloadResponse } from 'type-declarations';
import { downloadFile, getFileUri } from './thunks';

export interface FileDownloadState {
	fileUri: FileDownloadResponse;
	isDownloading: boolean;
	isErrored: boolean;
}

const initialState: FileDownloadState = {
	fileUri: {} as FileDownloadResponse,
	isDownloading: false,
	isErrored: false,
};

export const fileDownloadSlice = createSlice({
	name: 'fileDownload',
	initialState,
	reducers: {
		resetFileDownloadSlice: (state) => {
			Object.assign(state, initialState);
		},
		fileIsDownloaded: (state, action) => {
			state.fileUri = action.payload;
		},
		fileIsDownloading: (state, action) => {
			state.isDownloading = action.payload;
		},
		fileIsErrored: (state, action) => {
			state.isErrored = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getFileUri.pending, (state) => {
			state.isDownloading = true;
			state.fileUri = {} as FileDownloadResponse;
		}),
			builder.addCase(
				getFileUri.fulfilled,
				(state, action: PayloadAction<FileDownloadResponse>) => {
					state.isDownloading = false;
					state.isErrored = false;
					state.fileUri = action.payload;
				}
			),
			builder.addCase(getFileUri.rejected, (state) => {
				state.isDownloading = false;
				state.isErrored = true;
				state.fileUri = {} as FileDownloadResponse;
			}),
			builder.addCase(downloadFile.pending, (state) => {
				state.isDownloading = true;
				state.isErrored = false;
				state.fileUri = {} as FileDownloadResponse;
			}),
			builder.addCase(downloadFile.fulfilled, (state) => {
				state.isDownloading = false;
				state.isErrored = false;
				state.fileUri = {} as FileDownloadResponse;
			}),
			builder.addCase(downloadFile.rejected, (state) => {
				state.isDownloading = false;
				state.isErrored = true;
				state.fileUri = {} as FileDownloadResponse;
			});
	},
});

export const fileDownloadReducer = fileDownloadSlice.reducer;
export const fileDownloadActions = fileDownloadSlice.actions;
export const { resetFileDownloadSlice, fileIsDownloaded, fileIsErrored, fileIsDownloading } =
	fileDownloadActions;
