import { Card, CardActions, CardContent, Typography, Alert, Button } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch } from '@app/hooks/useReduxToolkit';
import AddOrEditUserDialog from './AddOrEditUserDialog';
import SendIcon from '@mui/icons-material/Send';
import { CrudDialogState, resendPartnerUserInvite } from 'state-services';
import { RhymeUser } from 'type-declarations';
import UserRoleChips from './UserRoleChips';
import DeleteUserDialog from './DeleteUserDialog';

type Props = {
	userId: string;
	users: RhymeUser[];
	editDialogState: CrudDialogState;
	deleteDialogState: CrudDialogState;
};

const UserDetails: FC<Props> = ({ userId, users, editDialogState, deleteDialogState }) => {
	const dispatch = useAppDispatch();
	let user = users.find((user: RhymeUser) => user.id === userId);
	if (user !== undefined && !user.userName) {
		user = { ...user };
	}

	const sendInvitation = () => {
		if (user?.userName !== undefined) {
			dispatch(resendPartnerUserInvite({ userName: user.userName }));
		}
	};

	return (
		<>
			{!user?.isConfirmed && (
				<Alert
					severity="info"
					sx={{ marginBottom: 1 }}
					action={
						<Button startIcon={<SendIcon />} onClick={() => sendInvitation()}>
							Send New Invite
						</Button>
					}
				>
					This user has not confirmed their details.
				</Alert>
			)}
			<Card variant="outlined">
				{user && (
					<CardContent>
						<Typography variant="h5" component="h5">
							{user.firstName} {user.lastName}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{user.userName}
						</Typography>
						<UserRoleChips user={user} />
					</CardContent>
				)}

				<CardActions sx={{ justifyContent: 'flex-end', display: 'flex' }}>
					<AddOrEditUserDialog user={user!} dialogState={editDialogState} />
					<DeleteUserDialog user={user!} dialogState={deleteDialogState} />
				</CardActions>
			</Card>
		</>
	);
};

export default UserDetails;
