export enum SliceStatus {
	IDLE = 'idle', //initial state before data loaded
	PENDING = 'pending', //fetching data
	REFRESHING = 'refreshing', //after initial data has loaded
	SUCCEEDED = 'succeeded', //thunk finished successfully
	REJECTED = 'rejected', //thunk errored
	SKIPPED = 'skipped', //thunk skipped, considered a success without completing process
}

/**
 * Members of SliceStatus enum that represent data has not been
 * fully retrieved from the backend at LEAST once.
 */
export const NotYetLoadedSliceStatuses: Array<SliceStatus> = [
	SliceStatus.IDLE,
	SliceStatus.PENDING,
];
