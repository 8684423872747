import { FC, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { EmbedDashboardUrlResponse } from 'type-declarations';
import { changeLighthouseDashboard, getAndSortDashboardsFromEmbedUrls } from 'state-services';
import { useAppDispatch } from '@app/hooks/useReduxToolkit';

interface Props {
	embedUrlsDetails: EmbedDashboardUrlResponse[];
	disabled: boolean;
}

const LighthouseTabs: FC<Props> = ({ embedUrlsDetails, disabled }) => {
	const dispatch = useAppDispatch();
	const [tabValue, setTabValue] = useState(0);

	const handleTabChanged = (tabValue: number, dashboardId: string) => {
		setTabValue(tabValue);
		dispatch(changeLighthouseDashboard(dashboardId));
	};

	const sortedDashboards = getAndSortDashboardsFromEmbedUrls(embedUrlsDetails);

	return (
		<Tabs
			aria-label="dashboard tabs"
			sx={{ color: 'primary.main' }}
			value={tabValue}
			onChange={(_event, newValue) => {
				handleTabChanged(newValue, sortedDashboards[newValue].dashboardId);
			}}
		>
			{sortedDashboards.map((d) => {
				return (
					<Tab
						key={d.dashboardId}
						label={d.tabText}
						disabled={disabled}
						sx={{ textTransform: 'none', fontSize: '16px', whiteSpace: 'nowrap', padding: 1 }}
					/>
				);
			})}
		</Tabs>
	);
};

export default LighthouseTabs;
