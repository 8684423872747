import {
	Box,
	Dialog,
	DialogTitle,
	FormControlLabel,
	IconButton,
	ListItemButton,
	ListItemText,
	Switch,
	TextField,
	Typography,
	Button,
	ListItem,
} from '@mui/material';
import React, { useEffect } from 'react';
import { FC } from 'react';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { clearUserMfaPreference, getTotpSeedCode, verifyTotpCode } from 'user-utilities';
import ClearIcon from '@mui/icons-material/Clear';
import { QRCodeCanvas } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import store from '@app/store';
import { SnackbarManager } from 'rhyme-component-library';

const SecurityMenu: FC = () => {
	const { preferredMfa, userName: email } = useAppSelector((state) => state.user);
	const [open, setOpen] = React.useState(false);

	const isNoMfaPreferred = () => {
		return preferredMfa === 'DISABLED';
	};

	const [checked, setChecked] = React.useState(!isNoMfaPreferred());
	const [code, setCode] = React.useState('');

	const getSeed = async (): Promise<string> => {
		setSeedCode(await getTotpSeedCode());
		return seedCode;
	};

	const [seedCode, setSeedCode] = React.useState('NOCODE');

	const turnMfaOnInstructions =
		'To set up MFA, you must first set the MFA status to Opted-in by clicking the toggle above.';
	const qrCodeInstructions =
		'To set up MFA security for your account, scan the QR code below with a multi-factor authentication app.';
	const longCodeInstructions =
		'If you have trouble scanning the QR code, you may use the code below to configure your authentication app manually.';
	const challengeCodeInstructions =
		'Once your authentication app is set up, enter the 6 digit code it displays in the box below.';
	const optedInMessage = 'You have enabled and set up MFA for your account.';

	useEffect(() => {
		getSeed();
	}, []);

	const saveClickHandler = async () => {
		if (checked && isNoMfaPreferred()) {
			const result = await verifyTotpCode(code, store);
			if (result) {
				SnackbarManager.show({
					message: 'MFA sign up successful!',
					type: 'success',
				});
			} else {
				SnackbarManager.show({
					message: 'MFA sign up failed!',
					type: 'error',
				});
			}
			setOpen(false);
		} else if (!checked && !isNoMfaPreferred()) {
			await clearUserMfaPreference(store);
			SnackbarManager.show({
				message: 'MFA setting cleared!',
				type: 'success',
			});
			setOpen(false);
		}
	};

	const handleClose = (event: React.MouseEvent<HTMLDivElement>, reason: string) => {
		if (reason === 'backdropClick') {
			return;
		}
		setOpen(false);
	};

	const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCode(event.target.value);
	};

	const optInOutChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleClick = () => {
		setOpen(!open);
	};

	const getQrCodeUrl = (secretCode: string): string => {
		return 'otpauth://totp/AWSCognito:' + email + '?secret=' + secretCode + '&issuer=' + 'Cognito';
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(seedCode);
	};

	const shouldMfaSaveButtonDisable = () => {
		const result =
			(!checked && isNoMfaPreferred()) ||
			(checked && isNoMfaPreferred() && !(code.length == 6)) ||
			(checked && !isNoMfaPreferred());
		return result;
	};

	return (
		<>
			<ListItem>
				<ListItemButton
					id="basic-button"
					aria-controls={open ? 'mfa-settings-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					<ListItemText>MFA Settings</ListItemText>
				</ListItemButton>
			</ListItem>
			<Dialog maxWidth={false} onClose={handleClose} open={open}>
				<Box m={2}>
					<DialogTitle
						sx={{
							justifyContent: 'space-between',
							display: 'flex',
							p: 0,
							minWidth: 300, // keeps whole dialog from being too small
						}}
						height={25}
					>
						MFA Settings
						<IconButton onClick={() => handleClick()} aria-label="close-dialog">
							{' '}
							<ClearIcon />
						</IconButton>
					</DialogTitle>
					<br />
					<Typography fontWeight={'bold'} variant="subtitle2">
						{'MFA Status:       '}
						<FormControlLabel
							sx={{ marginLeft: 0.5 }}
							control={<Switch size="small" onChange={optInOutChangeHandler} checked={checked} />}
							label={checked ? 'Opted-In' : 'Opted-Out'}
						/>
					</Typography>
					<br />
					{isNoMfaPreferred() && checked ? (
						<div>
							<Typography fontWeight={'bold'} variant="subtitle2">
								One-time Password Seed:
							</Typography>
							<Typography sx={{ justifyContent: 'center', display: 'flex' }} variant="caption">
								{qrCodeInstructions}
							</Typography>
							<Box sx={{ justifyContent: 'center', display: 'flex' }}>
								<QRCodeCanvas size={250} value={getQrCodeUrl(seedCode)}></QRCodeCanvas>
							</Box>
							<Typography marginTop={0.5} variant="caption">
								{longCodeInstructions}
							</Typography>
							<div
								style={{
									justifyContent: 'center',
									display: 'flex',
									flex: 'block',
								}}
							>
								<Typography marginTop={0.5} fontWeight={700} variant="subtitle1">
									{seedCode}
								</Typography>
								<IconButton size="small" onClick={handleCopyClick} aria-label="copy">
									<ContentCopyIcon fontSize="inherit" />
								</IconButton>
							</div>
							<br />
							<Typography variant="caption">{challengeCodeInstructions}</Typography>
							<Typography fontWeight={'bold'} variant="subtitle2">
								Enter your 6-digit code:
							</Typography>
							<TextField label="MFA Code" size="small" onChange={codeChangeHandler}></TextField>
						</div>
					) : checked ? (
						<Typography variant="subtitle2">{optedInMessage}</Typography>
					) : (
						<Typography variant="subtitle2">{turnMfaOnInstructions}</Typography>
					)}
					<div color="primary" style={{ justifyContent: 'flex-end', display: 'flex' }}>
						<Button disabled={shouldMfaSaveButtonDisable()} onClick={saveClickHandler}>
							Save
						</Button>
					</div>
				</Box>
			</Dialog>
		</>
	);
};

export default SecurityMenu;
