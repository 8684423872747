/* eslint-disable max-len */
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// Fonts
import WhyteRegular from '@fonts/whyte/Whyte-Regular.woff2';
import WhyteRegularItalic from '@fonts/whyte/Whyte-RegularItalic.woff2';
import WhyteMedium from '@fonts/whyte/Whyte-Medium.woff2';
import WhyteMediumItalic from '@fonts/whyte/Whyte-MediumItalic.woff2';
import WhyteBold from '@fonts/whyte/Whyte-Bold.woff2';
import WhyteBoldItalic from '@fonts/whyte/Whyte-BoldItalic.woff2';
import ArizonaMixRegular from '@fonts/arizona-mix/ArizonaMix-Regular.woff2';
import ArizonaMixRegularItalic from '@fonts/arizona-mix/ArizonaMix-RegularItalic.woff2';
import ArizonaMixMedium from '@fonts/arizona-mix/ArizonaMix-Medium.woff2';
import ArizonaMixMediumItalic from '@fonts/arizona-mix/ArizonaMix-MediumItalic.woff2';
import ArizonaMixBold from '@fonts/arizona-mix/ArizonaMix-Bold.woff2';
import ArizonaMixBoldItalic from '@fonts/arizona-mix/ArizonaMix-BoldItalic.woff2';
//! Excluded theme fonts:
// import ArizonaMixLight from '../fonts/arizona-mix/ArizonaMix-Light.woff2';
// import ArizonaMixLightItalic from '../fonts/arizona-mix/ArizonaMix-LightItalic.woff2';
// import ArizonaMixThin from '../fonts/arizona-mix/ArizonaMix-Thin.woff2';
// import ArizonaMixThinItalic from '../fonts/arizona-mix/ArizonaMix-ThinItalic.woff2';

/**
 * Create Rhyme specific material theme. Figma links may require invite to access.
 * @see https://mui.com/material-ui/customization/theming/
 * @see https://mui.com/material-ui/customization/how-to-customize/
 * @see https://www.figma.com/proto/XGg7OucRJBLGvnS1Cjuhp0/Accessibility
 * @see https://www.figma.com/file/xJAfwNJaDUDKWPbFYKg8o9/Updated-Rhyme-Library
 */
const theme = createTheme({
	// Default font family & fallbacks
	typography: {
		fontFamily: ['WhyteRegular', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
	},
	palette: {
		primary: {
			main: '#2F5233', // --rhyme-dark-green
			light: '#D6EDD8', // --rhyme-mint
		},
		secondary: {
			main: '#383F5E', //rhyme-navy
			light: '#3FC25F', // --rhyme-green
		},
		error: {
			main: '#CE3715', // --rhyme-accessibility-error
		},
		warning: {
			main: '#F7D133', // --rhyme-secondary-yellow
		},
		info: {
			main: '#567B78', // --rhyme-accessibility-dark-spruce
		},
		success: {
			main: '#3FC25F', // --rhyme-green
		},
		grey: {
			'500': '#E6EBEF', // --rhyme-light-gray
			'900': '#C7CCD1', // --rhyme-medium-gray
		},
	},
	components: {
		// Additional custom fonts
		MuiCssBaseline: {
			styleOverrides: `{
        @font-face {
          font-family: 'WhyteRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 100;
          src: local('WhyteRegular'), local('WhyteRegular'), url(${WhyteRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'WhyteRegularItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 100;
          src: local('WhyteRegularItalic'), local('WhyteRegularItalic'), url(${WhyteRegularItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'WhyteMedium';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('WhyteMedium'), local('WhyteMedium'), url(${WhyteMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'WhyteMediumItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 500;
          src: local('WhyteMediumItalic'), local('WhyteMediumItalic'), url(${WhyteMediumItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'WhyteBold';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('WhyteBold'), local('WhyteBold'), url(${WhyteBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'WhyteBoldItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 800;
          src: local('WhyteBoldItalic'), local('WhyteBoldItalic'), url(${WhyteBoldItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'ArizonaMixRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 100;
          src: local('ArizonaMixRegular'), local('ArizonaMixRegular'), url(${ArizonaMixRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'ArizonaMixRegularItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 100;
          src: local('ArizonaMixRegularItalic'), local('ArizonaMixRegularItalic'), url(${ArizonaMixRegularItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'ArizonaMixMedium';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('ArizonaMixMedium'), local('ArizonaMixMedium'), url(${ArizonaMixMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'ArizonaMixMediumItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 500;
          src: local('ArizonaMixMediumItalic'), local('ArizonaMixMediumItalic'), url(${ArizonaMixMediumItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'ArizonaMixBold';
          font-style: normal;
          font-display: swap;
          font-weight: 800;
          src: local('ArizonaMixBold'), local('ArizonaMixBold'), url(${ArizonaMixBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'ArizonaMixBoldItalic';
          font-style: italic;
          font-display: swap;
          font-weight: 800;
          src: local('ArizonaMixBoldItalic'), local('ArizonaMixBoldItalic'), url(${ArizonaMixBoldItalic}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      }`,
		},
		MuiButton: {
			defaultProps: {
				variant: 'contained',
				disableElevation: true,
			},
			styleOverrides: {
				// Use root to override the styles applied to the root element
				// Use selected to override the styles applied to the selected element
				root: {
					backgroundColor: 'var(--rhyme-primary-dark-green)',
					':hover': {
						color: 'var(--rhyme-accessibility-off-white)',
						backgroundColor: 'var(--rhyme-accessibility-medium-green)',
					},
					':focus': {
						color: 'var(--rhyme-accessibility-off-white)',
						backgroundColor: 'var(--rhyme-primary-dark-green)',
					},
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					border: '1px solid var(--rhyme-tertiary-medium-grey)',
					borderRadius: '0.5rem',
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					borderColor: 'var(--rhyme-tertiary-medium-grey)',
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					borderBottom: '1px solid var(--rhyme-tertiary-medium-grey)',
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: 'var(--rhyme-primary-dark-green)',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					':hover': {
						backgroundColor: 'var(--rhyme-primary-mint)',
					},
					':focus': {
						color: 'var(--rhyme-accessibility-off-white)',
						backgroundColor: 'var(--rhyme-primary-dark-green)',
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {},
		},
		MuiTypography: {
			styleOverrides: {},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
			},
		},
	},
});

export default responsiveFontSizes(theme);
