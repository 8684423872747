import { RhymeUser } from 'type-declarations';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	Chip,
	Stack,
} from '@mui/material';
import { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonRemove from '@mui/icons-material/PersonRemove';
import { closePartnerUsersDialog, CrudDialogState, openPartnerUsersDialog } from 'state-services';
import { useAppDispatch } from '@app/hooks/useReduxToolkit';

type Props = {
	user: RhymeUser;
	dialogState: CrudDialogState;
};

const DeleteUserDialog: FC<Props> = ({ user, dialogState }) => {
	const dispatch = useAppDispatch();
	const isOpen = dialogState.isDialogOpen;
	const userEmail = user.userName ?? '';

	const handleDelete = () => {
		dispatch(dialogState.confirmAction({ user }));
	};

	const openDialog = () => {
		dispatch(openPartnerUsersDialog(dialogState));
	};

	const closeDialog = () => {
		dispatch(closePartnerUsersDialog(dialogState));
	};
	return (
		<>
			<Button size="small" startIcon={<PersonRemove />} onClick={openDialog}>
				Delete User
			</Button>
			<Dialog open={isOpen}>
				<DialogContent sx={{ mt: 0 }}>
					<Typography variant="h6" color="primary">
						Are you sure you want to delete this user?
					</Typography>
					<Typography variant="body2" color="text.secondary">
						The deleted user will lose all access to your organization&apos;s Rhyme account.
					</Typography>
					<Stack direction="row" justifyContent="center" marginY={1} spacing={1}>
						<Chip label={`${user.firstName} ${user.lastName}`} />
						<Chip label={userEmail} />
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleDelete}
						startIcon={<CheckCircleIcon />}
						disabled={dialogState.isSubmitting}
					>
						{dialogState.isSubmitting ? 'Deleting...' : 'Confirm'}
					</Button>
					<Button
						onClick={closeDialog}
						startIcon={<CancelIcon />}
						disabled={dialogState.isSubmitting}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DeleteUserDialog;
