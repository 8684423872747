import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'aws-amplify/api';
import { NpiLookupProvider, RHYME_API_SERVICES } from 'type-declarations';
import { NpiState } from './slice';
import { fixNpiLookupProviderData } from './utilities';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

/**
 * Looks up the provided NPI using the NpiService.
 * Returns a Provider object if the NPI is found.
 * Validates that the NPI is 10 digits. If not,
 * returns null without calling the NpiService.
 */
export const lookupNpi = createAsyncThunk(
	'npi-thunk/lookup',
	async ({ npi}: { npi: string; }, { getState }) => {
		//NPIs must be 10 digits, so don't bother looking up anything that
		//is a different length than 10 digits.
		if (npi.length != 10) {
			return null;
		}

		//If we've already looked up this NPI and have the provider object cached,
		//just return that.
		const npiState = (getState() as any).npi as NpiState;
		const cachedProvider = npiState.providerCache.find((p) => p.npi == npi);
		if (cachedProvider) {
			return cachedProvider;
		}

		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `ontology/nppes/entity/${npi}/flat`,
			}).response;
			const data = (await body.json()) as NpiLookupProvider;
			return fixNpiLookupProviderData(data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			return null;
		}
	}
);
