import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPayerList } from './thunks';
import { PayerEntry, SliceStatus } from 'type-declarations';

export interface PayerListState {
	payerList: Array<PayerEntry>;
	status: SliceStatus;
}

const initialState: PayerListState = {
	payerList: [] as Array<PayerEntry>,
	status: SliceStatus.IDLE,
};

export const payerListSlice = createSlice({
	name: 'payerListThunk',
	initialState,
	reducers: {
		resetPayerListSlice: (state) => {
			Object.assign(state, initialState);
		},
		resetPayerList: (state) => {
			state.payerList = [] as Array<PayerEntry>;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPayerList.pending, (state) => {
			state.payerList = [] as Array<PayerEntry>;
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		}),
			builder.addCase(
				fetchPayerList.fulfilled,
				(state, action: PayloadAction<Array<PayerEntry>>) => {
					state.status = SliceStatus.SUCCEEDED;
					state.payerList = action.payload;
				}
			),
			builder.addCase(fetchPayerList.rejected, (state) => {
				state.status = SliceStatus.REJECTED;
				state.payerList = [] as Array<PayerEntry>;
			});
	},
});

export const payerListReducer = payerListSlice.reducer;
export const payerListActions = payerListSlice.actions;
export const { resetPayerListSlice, resetPayerList } = payerListActions;
