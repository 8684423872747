import { DiagnosisDetails, ProcedureDetails, SliceStatus } from 'type-declarations';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPrimaryDiagnosis, fetchPrimaryProcedure } from './thunks';

export interface ReferralState {
	primaryDiagnosis: DiagnosisDetails;
	diagnosisStatus: SliceStatus;
	primaryProcedure: ProcedureDetails;
	procedureStatus: SliceStatus;
}

export const initialState: ReferralState = {
	primaryDiagnosis: {} as DiagnosisDetails,
	diagnosisStatus: SliceStatus.IDLE,
	primaryProcedure: {} as ProcedureDetails,
	procedureStatus: SliceStatus.IDLE,
};

export const referralSlice = createSlice({
	name: 'referral',
	initialState,
	reducers: {
		resetReferral: (state) => {
			Object.assign(state, initialState);
		},
		addProcedureDetails: (state, action: PayloadAction<ProcedureDetails>) => {
			state.primaryProcedure = action.payload;
		},
		addDiagnosisDetails: (state, action: PayloadAction<DiagnosisDetails>) => {
			state.primaryDiagnosis = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPrimaryDiagnosis.pending, (state) => {
			if (state.diagnosisStatus === SliceStatus.IDLE) {
				state.diagnosisStatus = SliceStatus.PENDING;
			} else state.diagnosisStatus = SliceStatus.REFRESHING;
		});
		builder.addCase(fetchPrimaryDiagnosis.fulfilled, (state, action) => {
			state.primaryDiagnosis = action.payload;
			state.diagnosisStatus = SliceStatus.SUCCEEDED;
		});
		builder.addCase(fetchPrimaryDiagnosis.rejected, (state) => {
			state.primaryDiagnosis = {} as DiagnosisDetails;
			state.diagnosisStatus = SliceStatus.REJECTED;
		});
		builder.addCase(fetchPrimaryProcedure.pending, (state) => {
			if (state.procedureStatus === SliceStatus.IDLE) {
				state.procedureStatus = SliceStatus.PENDING;
			} else state.procedureStatus = SliceStatus.REFRESHING;
		});
		builder.addCase(fetchPrimaryProcedure.fulfilled, (state, action) => {
			state.primaryProcedure = action.payload;
			state.procedureStatus = SliceStatus.SUCCEEDED;
		});
		builder.addCase(fetchPrimaryProcedure.rejected, (state) => {
			state.primaryProcedure = {} as ProcedureDetails;
			state.procedureStatus = SliceStatus.REJECTED;
		});
	},
});

export const referralReducer = referralSlice.reducer;
export const referralActions = referralSlice.actions;
export const { resetReferral, addProcedureDetails, addDiagnosisDetails } = referralActions;
