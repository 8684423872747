import { NpiLookupProvider } from 'type-declarations';

/**
 * NPI Lookup response object (NpiLookupResponse in NpiService)
 * has different property names for first name, last name, and address 1
 * than what we use everywhere else for the provider object.
 * This corrects that data mismatch.
 */
export function fixNpiLookupProviderData(provider: NpiLookupProvider): NpiLookupProvider {
	if (provider.providerFirstName) {
		provider.firstName = provider.providerFirstName;
	}
	if (provider.providerLastName) {
		provider.lastName = provider.providerLastName;
	}
	if (provider.addressLine1) {
		provider.address1 = provider.addressLine1;
	}
	return provider;
}

/**
Takes an object as input and then returns a new object, containing only
property keys that were associated to a "truthy" value.
*/
export const onlyTruthyProps = (obj: any) =>
	Object.fromEntries(Object.entries(obj).filter(([, v]) => v));
