import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get } from 'aws-amplify/api';
import Axios from 'axios';
import { FileDownloadResponse, RHYME_API_SERVICES, RetrievableFileData } from 'type-declarations';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const getFileUri = createAsyncThunk(
	'fileDownload/getFileUri',
	async ({
		organizationId,
		caseId,
		file,
	}: {
		organizationId: string;
		caseId: string;
		file: RetrievableFileData;
	}) => {
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/case/${caseId}/file/${file.fileData.fileId}`,
			}).response;
			const data = (await body.json()) as unknown as FileDownloadResponse;
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(`Get file uri failed with error: ${statusCode}`);
			}
			throw new Error(`Get file uri failed with error: ${error}`);
		}
	}
);

export const downloadFile = createAsyncThunk(
	'file-download-thunk/downloadFile',
	async ({ uri }: { uri: string }) => {
		return Axios.get(uri, {
			responseType: 'arraybuffer',
			headers: { Accept: 'application/octet-stream' },
			timeout: 30000,
		})
			.then((response) => {
				if (response && response.status === 200) {
					return response;
				} else {
					throw new Error(`Failed to download file. Response status of ${response.status},`);
				}
			})
			.catch((err) => ({
				status: err.response.status,
				data: err.response.data,
				headers: err.response.headers,
			}));
	}
);
